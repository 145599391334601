const initialState = {
  players: {},
  events: {},
  news: {},
  instructions: {},
  members: {},
  activeEvents: [],
};

export default function (state = initialState, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    default:
      return newState;
  }
}
