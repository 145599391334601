import React, { useMemo, useState } from 'react';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Box
} from '@mui/material';
import { DynamicFormInputs } from 'pages/Common';

export const ConfigurationDialog = ({ open, onClose, onSave, configuration }) => {
  const [data, setdata] = useState(configuration);
  const inputs = useMemo(() =>
    [
      {
        field: 'configKey',
        label: 'Key',
        disabled: !!configuration,
        type: 'text',
        value: configuration?.configKey
      },
      {
        field: 'value',
        label: 'Value',
        type: 'text',
        value: configuration?.value
      },
      {
        field: 'description',
        label: 'Description',
        type: 'multiline',
        value: configuration?.description
      },
      {
        field: 'lang',
        label: 'Language',
        type: 'language',
        value: configuration?.lang
      },
    ], [configuration]);

  const onSubmit = (e) => {
    e.preventDefault();
    onSave(data);
    onClose();
  }

  return (
    <Dialog onClose={onClose} maxWidth="sm" fullWidth open={open}>
      <form onSubmit={onSubmit}>
        <DialogTitle>{configuration ? 'Edit' : 'Add'} Configuration</DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <DynamicFormInputs inputs={inputs} fullWidth={true} onChange={(value) => setdata(value)} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
