import {
  Edit as EditIcon,
  DeleteForever as DeleteForeverIcon
} from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import axios from 'axios';
import { SERVER_URL } from 'constants/serverUrl';
import moment from 'moment';
import { DataGridListPage } from 'pages/Common';
import { DataGridActionMenu } from 'pages/Common/DataGridActionMenu';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteDataGridRow } from 'store/actions';
import { pageKeys } from 'store/reducers/dataGrid.reducer';
import { getAuthHeader } from 'utils';
import { getEventName } from 'utils/event';
import { EventDayAddEdit } from './components/EventDayAddEdit';


export const EventSchedule = () => {
  const gamesById = useSelector(state => state.games.gamesById);
  const [ eventDetail, setEventDetail ] = useState(null);
  const [ eventDayId, setEventDayId ] = useState();
  const [ dialogOpen, setDialogOpen ] = useState(false);
  const { eventId } = useParams();
  const dispatch = useDispatch();

  const onDelete = useCallback((eventDayId) => {
    const confirmed = window.confirm("Are you sure you want to remove this permanently?");
    if (confirmed) {
      dispatch(deleteDataGridRow(`eventDay`, eventDayId, {}, {}, pageKeys.eventSchedule));
    }
  }, [dispatch]);

  const onEdit = (id) => {
    setEventDayId(id);
    setDialogOpen(true);
  }
  const onAdd = () => {
    setEventDayId(null);
    setDialogOpen(true);
  }

  useEffect(() => {
    axios.get(`${SERVER_URL}/event/${eventId}`, {
      headers: getAuthHeader()
    }).then(res => {
      setEventDetail(res.data);
    }).catch(err => {
      setEventDetail(null);
      toast.error(err.message);
    })
  }, [eventId]);

  const columns = useMemo(() => {
    return [
      {
        field: 'id',
        headerName: 'Id',
        width: 100,
      },
      {
        field: 'date',
        headerName: 'Date',
        valueFormatter: ({ value }) => value && moment(value).format('Y-MM-DD'),
        width: 150,
      },
      {
        field: 'time',
        headerName: 'Time',
        width: 150,
      },
      {
        field: 'planning',
        headerName: 'Planning',
        width: 400,
        valueGetter: ({data}) => data.i18n.find(item => item.lang === 'en')?.planning?.replace(/(<([^>]+)>)/gi, '')
      },
      {
        field: 'updatedAt',
        headerName: 'Updated At',
        valueFormatter: ({ value }) => value && moment(value).format('Y-MM-DD'),
        width: 120,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        cellStyle: {textAlign: 'center'},
        headerClass: 'text-center',
        cellRenderer: ({ data }) => (
          <DataGridActionMenu>
            <MenuItem onClick={() => onEdit(data.id)}><EditIcon/>Edit</MenuItem>
            <MenuItem onClick={() => onDelete(data.id)}><DeleteForeverIcon/>Delete</MenuItem>
          </DataGridActionMenu>
        ),
      },
    ];
  }, [onDelete]);
  if (!eventDetail) {
    return null;
  }
  return (
    <>
      <DataGridListPage
        columns={columns}
        apiEndpoint="eventDay"
        hideSearchInput
        heading={`Schedule - ${getEventName(eventDetail, gamesById)}`}
        pageKey={pageKeys.eventSchedule}
        onAdd={onAdd}
        rowsPerPage={50}
        initialQueryParams={{
          eventId
        }}
      />
      <EventDayAddEdit
        eventDayId={eventDayId}
        eventId={eventDetail ? eventDetail.id : null}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  )
}

