import React from "react";
import {
  Box, CircularProgress,
  Paper
} from "@mui/material";
import { TranslationEditor } from "components/TranslationEditor";
import { useDetailPage, DetailHeading } from "pages/Common";

export const EventTypeDetail = () => {
  const {
    id,
    data,
    loading,
    saving,
    onSave,
    onUpdateData,
  } = useDetailPage({
    apiEndpoint: 'eventType',
  });
  const name = data && data.i18n ? data.i18n.find(item => item.lang === 'en').label : null;

  return (
    <Paper>
      <Box p={2}>
        {loading &&
          <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        {!!data &&
          <>
            <DetailHeading
              listLink="/eventType"
              listName="Event Types"
              title={id === 'new' ? "New Event Type" : name}
              saving={saving}
              onSave={onSave}
            />
            <Paper sx={{ padding: 2 }}>
              <TranslationEditor
                i18n={data.i18n}
                inputs={[
                  {field: 'label', type: 'text', label: 'Name', fullWidth: false},
                  {field: 'slug', type: 'text', label: 'Slug', fullWidth: false},
                ]}
                onUpdate={(i18n) => onUpdateData({i18n})}
              />
            </Paper>
          </>
        }
      </Box>
    </Paper>
  )
};
