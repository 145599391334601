import {
  Edit as EditIcon
} from '@mui/icons-material';
import { Box, MenuItem } from '@mui/material';
import { DataGridActionMenu, DataGridListPage } from 'pages/Common';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { pageKeys } from 'store/reducers/dataGrid.reducer';


export const CountryList = () => {
  const history = useHistory();
  
  const columns = useMemo(() => {

    return [
      {
        field: 'id',
        headerName: 'Id',
        width: 80,
      },
      {
        field: 'name',
        headerName: 'Name',
        width: 300,
      },
      {
        field: 'cio',
        headerName: 'Cio',
        width: 100,
      },
      {
        field: 'iso3',
        headerName: 'Iso3',
        width: 100,
      },
      {
        field: 'iso2',
        headerName: 'Flag',
        width: 100,
        cellRenderer: ({ value }) => (
          <Box display="flex" alignItems="center" height="100%">
            <img width="40" src={`https://flagcdn.com/${value.toLowerCase()}.svg`} alt={value}/>
          </Box>
        ),
      },
      {
        field: 'slug',
        headerName: 'Slug',
        width: 200,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        cellStyle: {textAlign: 'center'},
        headerClass: 'text-center',
        cellRenderer: ({ data }) => (
          <>
            <DataGridActionMenu>
              <MenuItem onClick={() => history.push(`/country/${data.id}`)}><EditIcon />Edit</MenuItem>
            </DataGridActionMenu>
          </>
        ),
      },
    ]
  }, [history]);

  return <DataGridListPage
    columns={columns}
    filters={[]}
    apiEndpoint="country"
    heading="Countries"
    hideSearchInput
    hasPagination={false}
    pageKey={pageKeys.countryList}
  />
}

