
import { FormControlLabel, Grid, Switch, TextField, Button, InputLabel, FormControl, Select, MenuItem, Autocomplete, Typography } from '@mui/material';
import {
  GameSelect,
  SportSelect,
  EventSelect,
  UserSelect,
  EventTypeSelect,
  CountrySelect,
  NewsEditor,
  LanguageSelect,
  PlayerSelect,
  RemoteSourceAutoComplete
} from 'components';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers';

export const getFieldValuesObject = (items) => {
  const values = {};
  items.forEach(item => {
    if (item.field) {
      values[item.field] = item.value
    }
  });
  return values;
}

export const formatDateTimeWithTimezone = (input, timezone) => {
  if (timezone && input) {
    return 'Event Local Time: ' + moment(input).tz(timezone).format('MM/DD/YYYY HH:mm');
  }
  return '';
}

export const getSwitchValue = (value, dataType) => {
  if (dataType === 'int') {
    return value ? 1 : 0;
  }
  return value;
}

/**
 * Dynamic forms inputs based on configration.
 * @param inputs: {field: string, label: string, type: string, value: any}[]
 */
export const DynamicFormInputs = ({ inputs, onChange, fullWidth = false, showReset = false, vertical = true }) => {
  const [inputValues, setInputValues] = useState(getFieldValuesObject(inputs));

  useEffect(() => {
    setInputValues(getFieldValuesObject(inputs))
  }, [inputs]);

  const formInputChange = (field, value) => {
    const newValues = {
      ...inputValues,
      [field]: value
    };
    setInputValues(newValues);
    onChange(newValues);
  }

  const onReset = () => {
    const initValues = getFieldValuesObject(inputs);
    setInputValues(initValues);
    onChange(initValues);
  }

  return (
    <>
      <Grid container spacing={2}>
        {inputs.map((input, index) => (
          <Grid key={index} item
            lg={input.fullWidth || fullWidth ? 12 : 6}
            md={input.fullWidth || fullWidth ? 12 : 6}
            sm={12} xs={12}>
            {input.type === 'date' &&
              <FormControl fullWidth>
                <DatePicker
                  fullWidth
                  clearable={true}
                  disabled={input.disabled}
                  label={input.label}
                  value={moment(inputValues[input.field])}
                  onChange={(value) => formInputChange(input.field, value)}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </FormControl>
            }
            {input.type === 'datetime' &&
              <FormControl fullWidth>
                <DateTimePicker
                  fullWidth
                  disabled={input.disabled}
                  clearable={true}
                  ampm={false}
                  label={input.label}
                  timezone={input.timezone}
                  value={moment(inputValues[input.field])}
                  onChange={(value) => formInputChange(input.field, value)}
                  slotProps={{ textField: { size: 'small' } }}
                />
                <Typography marginLeft={1} color="#3f51b5">{`Time zone: ${input.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone}`}</Typography>
              </FormControl>
            }
            {input.type === 'switch' &&
              <FormControlLabel control={
                <Switch
                  disabled={input.disabled}
                  value={Boolean(inputValues[input.field])}
                  checked={Boolean(inputValues[input.field])}
                  onChange={(e) => formInputChange(input.field, getSwitchValue(e.target.checked, input.dataType))}
                />
              } label={input.label} />
            }
            {input.type === 'game' &&
              <GameSelect
                gameId={inputValues[input.field]}
                placeholder={input.label}
                disabled={input.disabled}
                onChange={(gameId) => formInputChange(input.field, gameId)}
              />
            }
            {input.type === 'sport' &&
              <SportSelect
                sportId={inputValues[input.field]}
                placeholder={input.label}
                disabled={input.disabled}
                onChange={(sportId) => formInputChange(input.field, sportId)}
              />
            }
            {input.type === 'event' &&
              <EventSelect
                eventId={inputValues[input.field]}
                placeholder={input.label}
                disabled={input.disabled}
                onChange={(event) => formInputChange(input.field, event ? event.id : null)}
              />
            }
            {input.type === 'country' &&
              <CountrySelect
                countryId={inputValues[input.field]}
                placeholder={input.label}
                disabled={input.disabled}
                onChange={(country) => formInputChange(input.field, country ? country.id : null)}
              />
            }
            {input.type === 'user' &&
              <UserSelect
                disabled={input.disabled}
                userId={inputValues[input.field]}
                placeholder={input.label}
                onChange={(user) => formInputChange(input.field, user ? user.id : null)}
              />
            }
            {input.type === 'eventType' &&
              <EventTypeSelect
                eventTypeId={inputValues[input.field]}
                placeholder={input.label}
                disabled={input.disabled}
                onChange={(eventType) => formInputChange(input.field, eventType ? eventType.id : null)}
              />
            }
            {input.type === 'language' &&
              <LanguageSelect
                lang={inputValues[input.field]}
                disabled={input.disabled}
                onChange={(lang) => formInputChange(input.field, lang)}
              />
            }
            {input.type === 'player' &&
              <PlayerSelect
                playerId={inputValues[input.field]}
                disabled={input.disabled}
                onChange={(player) => formInputChange(input.field, player)}
              />
            }
            {input.type === 'select' &&
              <FormControl size="small" fullWidth>
                <InputLabel id={input.label.replace(/\s+/g, '-')}>{input.label}</InputLabel>
                <Select
                  labelId={input.label.replace(/\s+/g, '-')}
                  label={input.label}
                  value={input.value}
                  disabled={input.disabled}
                  onChange={(e) => formInputChange(input.field, e.target.value)}
                >
                  {input.options.map(item =>
                    <MenuItem value={item.id} key={item.id}>
                      {item.label}
                    </MenuItem>)}
                </Select>
              </FormControl>
            }
            {input.type === 'autocomplete' &&
              <Autocomplete
                options={input.options}
                disabled={input.disabled}
                value={inputValues[input.field]}
                onChange={(e, value) => formInputChange(input.field, value)}

                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={input.label}
                    size="small"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            }
            {input.type === 'remoteSource' &&
              <RemoteSourceAutoComplete
                apiPath="equipmentType"
                queryParams={input.queryParams || {}}
                localFilter={true}
                selectedId={inputValues[input.field]}
                disabled={input.disabled}
                getOptionLabel={input.getOptionLabel}
                onChange={(value) => formInputChange(input.field, value.id)}
                placeholder={input.label}
              />
            }
            {input.type === 'text' &&
              <TextField
                label={input.label}
                disabled={input.disabled}
                value={inputValues[input.field]}
                onChange={(e) => formInputChange(input.field, e.target.value)}
                size="small"
                variant="outlined"
                fullWidth
              />
            }
            {input.type === 'number' &&
              <TextField
                type="number"
                label={input.label}
                disabled={input.disabled}
                inputProps={{
                  step: input.step || 1
                }}
                value={inputValues[input.field]}
                onChange={(e) => formInputChange(input.field, Number(e.target.value))}
                size="small"
                variant="outlined"
                fullWidth
              />
            }
            {input.type === 'multiline' &&
              <TextField
                label={input.label}
                placeholder={input.label}
                multiline
                fullWidth
                disabled={input.disabled}
                rows={input.rows || 3}
                value={inputValues[input.field] || ""}
                variant="outlined"
                onChange={(e) => formInputChange(input.field, e.target.value)}
              />
            }
            {input.type === 'richtext' &&
              <>
                <Typography variant="body2" color="textSecondary" marginBottom={1}>
                  {input.label}
                </Typography>
                <NewsEditor
                  placeholder={input.label}
                  text={inputValues[input.field] || ""}
                  onChange={(text) => formInputChange(input.field, text)}
                />
              </>
            }
          </Grid>
        ))}
      </Grid>
      {showReset &&
        <Button variant="outlined" color="secondary" onClick={onReset} sx={vertical ? {marginTop: 2} : {marginLeft: 2}}>Reset</Button>
      }
    </>
  );
}

