import { Button, Card, CardContent, CircularProgress, Typography, Box } from "@mui/material";
import Alert from '@mui/material/Alert';
import { makeStyles } from "@mui/styles";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { UPLOADED_MEDIA_URL } from "../../constants/serverUrl";
import { login, logout } from "auth";
import { URL_ADMIN } from "routes.admin";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    zIndex: 4000,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.primary.main,
    alignItems: "center",
    justifyContent: "center",
  },

  wrapper: {
    flex: "none",
    maxWidth: "400px",
    width: "100%",
    margin: "0 auto",
  },

}));


export const Signin = () => {
  const { userInfo, loading, error } = useSelector(state => state.auth);
  const params = useParams();
  const classes = useStyles();
  let redirectUrl = URL_ADMIN;
  try {
    if (params.redirectUrl) redirectUrl = params.redirectUrl;
  } catch (_) {
    console.log(_);
  }
  if (userInfo && userInfo.isAdmin) {
    return <Redirect to={redirectUrl} />;
  }
  return (
    <div className={classes.container}>

      <div className={classes.wrapper}>
        {error && (
          <Alert severity="error">{error.toString()}</Alert>
        )}
        {userInfo && !userInfo.isAdmin &&
          <Button onClick={logout}>Sign Out</Button>
        }
        <Card size="large">
          <CardContent>
            <div className="text-xs-center pb-xs">
              <img
                src={
                  UPLOADED_MEDIA_URL +
                  "/assets/static/images/logos/kozoom--dark.png"
                }
                alt="Logo"
                height={50}
              />
              <br />
              {!loading && !userInfo &&
                <React.Fragment>
                  <Typography component="p" className="mb-1">
                    Admin Sign In
                  </Typography>
                  <Button onClick={login} variant="contained" color="primary">
                    Sign in
                  </Button>
                </React.Fragment>
              }
              {!loading && userInfo && !userInfo.isAdmin &&
                <>
                  <Box mb={2}>
                    <Alert severity="error">The logged-in user is not admin</Alert>
                  </Box>
                  <Button variant="contained" color="error" onClick={logout}>Sign Out</Button>
                </>
              }
              {loading && <CircularProgress />}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

