import React from "react";
import { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, List, ListItem, ListSubheader, Typography } from "@mui/material";
import {
  Person as PersonIcon,
} from "@mui/icons-material";

import axios from "axios";
import { EventSelect, StatCard } from "components";
import { SERVER_URL } from "constants/serverUrl";
import { getAuthHeader } from "utils";
import { secondsToHours } from "utils/time";

const SectionHeading = (props) => <Typography sx={{ fontSize: 24, marginBottom: 2, fontWeight: 500 }} component="h2">{props.children}</Typography>;
const SubHeading = (props) => <Typography sx={{ fontSize: 18, marginBottom: 1, fontWeight: 500 }} component="h3">{props.children}</Typography>;

const BriefInfoPanel = () => {
  const [userStatistics, setUserStatistics] = useState(null);
  const [eventStatistics, setEventStatistics] = useState(null);
  const [userLoading, setUserLoading] = useState(false);
  const [eventLoading, setEventLoading] = useState(false);
  const [eventId, setEventId] = useState(null);
  useEffect(() => {
    setUserLoading(true);
    axios.get(`${SERVER_URL}/userStatistics`, { headers: getAuthHeader() }).then(res => {
      setUserStatistics(res.data);
    }).catch(e => console.warn(e)).finally(() => { setUserLoading(false) });
  }, []);

  useEffect(() => {
    if (eventId) {
      setEventLoading(true);
      axios.get(`${SERVER_URL}/eventStatistics/${eventId}`, { headers: getAuthHeader() }).then(res => {
        setEventStatistics(res.data);
      }).catch(e => console.warn(e)).finally(() => { setEventLoading(false) });
    }
  }, [eventId]);

  return (
    <Box mx={1} mt={1} mb={3}>
      <Box mb={3}>
        <SectionHeading>User Statistics</SectionHeading>
        {userLoading &&
          <Box mt={4} textAlign="center">
            <CircularProgress />
          </Box>
        }
        {userStatistics &&
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard
                type="fill"
                title="Total"
                value={userStatistics.user.total}
                icon={<PersonIcon />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard
                type="fill"
                title="Active last 30 days"
                value={Number(userStatistics.user.activeLast30Days)}
                icon={<PersonIcon />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard
                type="fill"
                title="Inactive"
                value={Number(userStatistics.user.inactive)}
                icon={<PersonIcon />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard
                type="fill"
                title="Bannded"
                value={Number(userStatistics.user.banned)}
                icon={<PersonIcon />}
              />
            </Grid>
          </Grid>
        }
      </Box>
      <Box mt={3}>
        <SectionHeading>Event Statistics</SectionHeading>
        <Box maxWidth={400}><EventSelect onChange={(event) => event && setEventId(event.id)} /></Box>
        {eventLoading &&
          <Box mt={4} textAlign="center">
            <CircularProgress />
          </Box>
        }
        {eventStatistics && !eventLoading &&
          <>
            <Box mt={2}>
              <SubHeading>Membership Count</SubHeading>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <List
                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                    subheader={
                      <ListSubheader sx={{ fontSize: 18 }}>Live Viewers</ListSubheader>
                    }
                  >
                    <ListItem>Free: {eventStatistics.liveMembershipCount?.find(item => item.subscriptionType == 'free')?.count || 0}</ListItem>
                    <ListItem>Event: {eventStatistics.liveMembershipCount?.find(item => item.subscriptionType == 'event-pass')?.count || 0}</ListItem>
                    <ListItem>Premium: {eventStatistics.liveMembershipCount?.find(item => item.subscriptionType == 'premium-pass')?.count || 0}</ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <List
                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                    subheader={
                      <ListSubheader sx={{ fontSize: 18 }}>Replay Viewers</ListSubheader>
                    }
                  >
                    <ListItem>Free: {eventStatistics.replayMembershipCount?.find(item => item.subscriptionType == 'free')?.count || 0}</ListItem>
                    <ListItem>Event: {eventStatistics.replayMembershipCount?.find(item => item.subscriptionType == 'event-pass')?.count || 0}</ListItem>
                    <ListItem>Premium: {eventStatistics.replayMembershipCount?.find(item => item.subscriptionType == 'premium-pass')?.count || 0}</ListItem>
                  </List>
                </Grid>
              </Grid>
            </Box>

            <Box mt={2}>
              <SubHeading>Duration in hours</SubHeading>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <List
                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                    subheader={
                      <ListSubheader sx={{ fontSize: 18 }}>Live Viewers</ListSubheader>
                    }
                  >
                    <ListItem>Free: {secondsToHours(eventStatistics.liveWatchDuration?.find(item => item.subscriptionType == 'free')?.duration || 0)}</ListItem>
                    <ListItem>Event: {secondsToHours(eventStatistics.liveWatchDuration?.find(item => item.subscriptionType == 'event-pass')?.duration || 0)}</ListItem>
                    <ListItem>Premium: {secondsToHours(eventStatistics.liveWatchDuration?.find(item => item.subscriptionType == 'premium-pass')?.duration || 0)}</ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <List
                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                    subheader={
                      <ListSubheader sx={{ fontSize: 18 }}>Replay Viewers</ListSubheader>
                    }
                  >
                    <ListItem>Free: {secondsToHours(eventStatistics.replayWatchDuration?.find(item => item.subscriptionType == 'free')?.duration || 0)}</ListItem>
                    <ListItem>Event: {secondsToHours(eventStatistics.replayWatchDuration?.find(item => item.subscriptionType == 'event-pass')?.duration || 0)}</ListItem>
                    <ListItem>Premium: {secondsToHours(eventStatistics.replayWatchDuration?.find(item => item.subscriptionType == 'premium-pass')?.duration || 0)}</ListItem>
                  </List>
                </Grid>
              </Grid>
            </Box>
          </>
        }
      </Box>
    </Box>
  )
}

export default BriefInfoPanel;
