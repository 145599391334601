import { io } from "socket.io-client";
import {
  CHAT_SOCKET_SERVER_URL
} from "constants/serverUrl";
import { store } from "../store";


export const socketIo = io(CHAT_SOCKET_SERVER_URL, {
  path: "/live-server/socket.io",
});

socketIo.on('connection', () => {
  const userInfo = store.getState().auth.userInfo;
  if (userInfo) {
    socketIo.emit('user_logged_in', userInfo);
  }
});