import React from 'react';
import { DataGridListPage } from 'pages/Common';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { pageKeys } from 'store/reducers/dataGrid.reducer';


export const CouponList = () => {
  const history = useHistory();
  
  const columns = useMemo(() => {

    return [
      {
        field: 'coupon_name',
        headerName: 'Name',
        width: 200,
      },
      {
        field: 'coupon_code',
        headerName: 'Code',
        width: 200,
      },
      {
        field: 'valid_upto',
        headerName: 'Valid Up To',
        width: 200,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 150,
      },
      {
        field: 'used_redemption',
        headerName: 'Used',
        width: 150,
      },
      {
        field: 'discount',
        headerName: 'Discount',
        valueFormatter: ({ data }) => `${data.discount} ${data.discount_type === 'percent' ? '%' : ''}`,
        flex: 1,
    },
      // {
      //   field: 'actions',
      //   headerName: 'Actions',
      //   flex: 1,
      //   cellStyle: {textAlign: 'center'},
      //   headerClass: 'text-center',
      //   cellRenderer: ({ data }) => (
      //     <>
      //       <DataGridActionMenu>
      //       </DataGridActionMenu>
      //     </>
      //   ),
      // },
      
    ]
  }, []);

  return <DataGridListPage
    columns={columns}
    filters={[]}
    apiEndpoint="coupon"
    heading="Coupons"
    hideSearchInput
    rowsPerPage={50}
    pageKey={pageKeys.countryList}
  />
}

