import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Hidden from "@mui/material/Hidden";
import withStyles from '@mui/styles/withStyles';
import Drawer from "@mui/material/Drawer";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import SidebarItem from "./SidebarItem";
import SidebarStyles from "styles/Components/Sidebar";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width="xs" />;

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

class Sidebar extends Component {
  state = {
    activeRoute: undefined,
  };

  toggleMenu = (index) => {
    if (this.state.activeRoute === index) index = undefined;
    this.setState({ activeRoute: index });
  };

  render() {
    const { classes, opened, toggleDrawer, routes } = this.props;
    const { activeRoute } = this.state;
    const menu = (
      <List component="div">
        {routes.map((route, index) => {
          if (route.invisibleOnSidebar === true) return null;
          return (
            <SidebarItem
              key={index}
              index={index}
              route={route}
              activeRoute={activeRoute}
              toggleMenu={this.toggleMenu}
            />
          );
        })}
      </List>
    );
    return (
      <Fragment>
        <Hidden mdDown>
          <Drawer
            variant="persistent"
            classes={{
              paper: classes.drawerPaper,
            }}
            open={opened}
            ModalProps={{
              keepMounted: false,
              className: classes.modal,
              BackdropProps: {
                className: classes.backdrop,
              },
              onBackdropClick: toggleDrawer,
            }}
          >
            {menu}
          </Drawer>
        </Hidden>
        <Hidden mdUp>
          <SwipeableDrawer
            variant="temporary"
            classes={{
              paper: classes.drawerPaper,
            }}
            open={opened}
            onClose={toggleDrawer}
            onOpen={toggleDrawer}
            disableBackdropTransition={!iOS}
            ModalProps={{
              keepMounted: false,
              className: classes.modal,
              BackdropProps: {
                className: classes.backdrop,
              },
              onBackdropClick: toggleDrawer,
            }}
          >
            {menu}
          </SwipeableDrawer>
        </Hidden>
      </Fragment>
    );
  }
}

Sidebar.prototypes = {
  classes: PropTypes.object.isRequired,
  opened: PropTypes.func,
  toggleDrawer: PropTypes.func,
  closeDrawer: PropTypes.func,
  openDrawer: PropTypes.func,
  routes: PropTypes.object,
};

const SidebarWithRouter = withRouter(Sidebar);

export default withStyles(SidebarStyles)(withWidth()(SidebarWithRouter));
