import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import PlaylistDeleteForeverIcon from '@mui/icons-material/PlaylistRemove';
import { Box, Button, Grid, MenuItem, TextField } from '@mui/material';
import axios from 'axios';
import { SERVER_URL } from 'constants/serverUrl';
import { DataGridListPage } from 'pages/Common';
import { DataGridActionMenu } from 'pages/Common/DataGridActionMenu';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addDataGridRow, deleteDataGridRow, editDataGridRow, getDataGridList, saveRowRankings } from 'store/actions';
import { pageKeys } from 'store/reducers/dataGrid.reducer';
import { getAuthHeader } from 'utils';
import { getEventName } from 'utils/event';
import { RankingAddEdit } from './components/RankingAddEdit';
import { isNil, omit, omitBy } from 'lodash';
import { LoadingButton } from '@mui/lab';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';

export const EventRankings = () => {
  const gamesById = useSelector(state => state.games.gamesById);
  const [eventDetail, setEventDetail] = useState({});
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingRanking, setEditingRanking] = useState(null);
  const [createSheetLoading, setCreateSheetLoading] = useState(false);
  const [importSheetLoading, setImportSheetLoading] = useState(false);
  const [sheetUrl, setSheetUrl] = useState('');
  const rankingState = useSelector(state => state.dataGrid[pageKeys.eventRankings]);


  const onDelete = useCallback((id) => {
    const confirmed = window.confirm("Are you sure you want to remove this player from the event rankings?");
    if (confirmed) {
      dispatch(deleteDataGridRow(`ranking`, id, {}, {}, pageKeys.eventRankings));
    }
  }, [dispatch]);

  const createGoogleSpreadsheet = useCallback(() => {
    const confirmed = window.confirm("This will create a Google Spreadsheet to manage rankings. Are you sure you want to process?");
    if (confirmed) {
      setCreateSheetLoading(true);
      axios.post(`${SERVER_URL}/ranking`, {
        createGoogleSpreadSheet: true,
        eventId
      }, {headers: getAuthHeader()}).then((res) => {
        setCreateSheetLoading(false);
        window.open(res.data.url, '_blank');
      }, () => {
        toast.error('Cannot create Google Spreadsheet');
        setCreateSheetLoading(false);
      });
    }

  }, [eventId]);

  const importFromGoogleSpreadsheet = useCallback(() => {
    const matches = sheetUrl.match(new RegExp('https://docs.google.com/spreadsheets/d/([^/]+)/edit'));
    const spreadsheetId = matches ? matches[1] : null;
    if (spreadsheetId) {
      setImportSheetLoading(true);
      axios.post(`${SERVER_URL}/ranking`, {
        importFromGoogleSpreadsheet: true,
        spreadsheetId
      }, {headers: getAuthHeader()}).then((res) => {
        setImportSheetLoading(false);
        toast.success('Imported successfully');
        dispatch(getDataGridList('ranking', {eventId}, pageKeys.eventRankings));
      }, () => {
        toast.error('Cannot import from Google Spreadsheet');
        setImportSheetLoading(false);
      });
    }
  }, [dispatch, eventId, sheetUrl]);

  const onSaveRankings = (ids) => {
    dispatch(saveRowRankings('ranking', { ids }, {}, pageKeys.eventRankings));
  }

  const onSave = (data) => {
    const saveData = omitBy(data, isNil);
    if (saveData.id) {
      dispatch(editDataGridRow(`ranking`, saveData.id, omit({ ...saveData, playerId: saveData.player.id }, ['player', 'createdAt', 'updatedAt']), {}, pageKeys.eventRankings));
    } else {
      dispatch(addDataGridRow(`ranking`, { ...saveData, eventId: Number(eventId), playerId: saveData.player.id }, {}, pageKeys.eventRankings));
    }
  }

  useEffect(() => {
    axios.get(`${SERVER_URL}/event/${eventId}`, {
      headers: getAuthHeader()
    }).then(res => {
      setEventDetail(res.data);
    }).catch(err => {
      setEventDetail({});
      toast.error(err.message);
    })
  }, [eventId]);

  const columns = useMemo(() => {
    return [
      {
        field: 'rank',
        headerName: 'Rank',
        width: 80,
        rowDrag: true,
      },
      {
        field: 'player',
        headerName: 'Player',
        valueFormatter: ({ value }) => value ? `${value.firstName} ${value.lastName}` : '',
        flex: 1,
      },
      {
        field: 'money',
        headerName: 'Prize Money',
        width: 100,
      },
      {
        field: 'day',
        headerName: 'Day',
        width: 80,
      },
      {
        field: 'won',
        headerName: 'Won',
        width: 80,
      },
      {
        field: 'lost',
        headerName: 'Lost',
        width: 80,
      },
      {
        field: 'run',
        headerName: 'Run',
        width: 80,
      },
      {
        field: 'points',
        headerName: 'Points',
        width: 80,
      },
      {
        field: 'innings',
        headerName: 'Innings',
        width: 80,
      },
      {
        field: '',
        headerName: 'Average',
        valueFormatter: ({ data }) => data.innings ? (data.points/data.innings).toString().substring(0, 5) : '',
        width: 100,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        cellStyle: { textAlign: 'center' },
        headerClass: 'text-center',
        width: 100,
        cellRenderer: ({ data }) => (
          <DataGridActionMenu>
            <MenuItem onClick={() => onDelete(data.id)}><PlaylistDeleteForeverIcon />Remove</MenuItem>
            <MenuItem onClick={() => { setEditingRanking(data); setDialogOpen(true) }}><EditIcon />Edit</MenuItem>
          </DataGridActionMenu>
        ),
      },
    ];
  }, [onDelete]);

  return (
    <>
      <DataGridListPage
        columns={columns}
        apiEndpoint="ranking"
        hideSearchInput
        heading={`Rankings - ${getEventName(eventDetail, gamesById)}`}
        pageKey={pageKeys.eventRankings}
        onAdd={() => { setEditingRanking(null); setDialogOpen(true) }}
        onSaveRankings={onSaveRankings}
        initialQueryParams={{
          eventId
        }}
      >
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextField onChange={(e) => {setSheetUrl(e.target.value)}}
                label="Google Spreadsheet URL" variant="outlined" size='small' fullWidth />
            </Grid>
            <Grid item md={1}>
              <LoadingButton loading={importSheetLoading} variant="outlined" onClick={importFromGoogleSpreadsheet}>
                Import
              </LoadingButton>
            </Grid>
            <Grid item md={3}>
              <LoadingButton loading={createSheetLoading} variant="contained" color="success" onClick={createGoogleSpreadsheet}>
                Create Google Spreadsheet
              </LoadingButton>
            </Grid>
            <Grid item md={2} textAlign="right">
              <NavLink to={`/event/${eventId}`}>
                <Button variant="outlined">Go to Event</Button>
              </NavLink>
            </Grid>
          </Grid>
        </Box>
      </DataGridListPage>
      <RankingAddEdit
        ranking={editingRanking}
        total={rankingState?.ids?.length}
        onSave={onSave}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  )
}

