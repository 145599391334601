import {
  Edit as EditIcon,
  DeleteForever as DeleteForeverIcon,
  Check as CheckIcon,
  ContentCopy as ContentCopyIcon
} from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import moment from 'moment';
import { DataGridActionMenu, DataGridListPage } from 'pages/Common';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteDataGridRow, saveRowRankings, addDataGridRow } from 'store/actions';
import { pageKeys } from 'store/reducers/dataGrid.reducer';
import { getGameName } from 'utils';


export const SlideList = () => {
  const gamesById = useSelector(state => state.games.gamesById);
  const history = useHistory();
  const dispatch = useDispatch();

  const onDeleteSlide = useCallback((slideId) => {
    const confirmed = window.confirm("Are you sure you want to delete this news permanently?");
    if (confirmed) {
      dispatch(deleteDataGridRow(`slide`, slideId, {}, {}, pageKeys.slideList));
    }
  }, [dispatch]);

  const onSaveRankings = (ids) => {
    dispatch(saveRowRankings('slide', {ids}, {}, pageKeys.slideList));
  }

  const onDuplicate = useCallback((slide) => {
    dispatch(addDataGridRow('slide', slide, {}, pageKeys.slideList));
  }, [dispatch]);
  
  const columns = useMemo(() => {

    return [
      {
        field: 'slideOrder',
        headerName: 'Rank',
        width: 80,
        rowDrag: true,
      },
      {
        field: 'image',
        headerName: 'Image',
        cellRenderer: ({value}) => <img src={value} alt="thumb" width="100%"/>,
        width: 120,
      },
      {
        field: 'gameId',
        headerName: 'Game',
        valueFormatter: ({ value }) => getGameName(gamesById, value),
        width: 100,
      },
      {
        field: 'title',
        headerName: 'Title',
        flex: 1,
      },
      {
        field: 'site',
        headerName: 'Site',
        cellStyle: { textAlign: 'center' },
        headerClass: 'text-center',
        width: 80,
      },
      {
        field: 'lang',
        headerName: 'Lang',
        cellStyle: { textAlign: 'center' },
        headerClass: 'text-center',
        width: 80,
      },
      {
        field: 'time',
        headerName: 'Time',
        valueFormatter: ({ data }) => `${moment(data.startTime).format('Y-MM-DD H:m')} -> ${moment(data.endTime).format('Y-MM-DD H:m')}`,
        width: 280,
      },
      {
        field: 'isLive',
        headerName: 'On Air',
        width: 80,
        cellRenderer: ({ value }) => (
          value ? <CheckIcon color="success" sx={{verticalAlign: 'middle'}} /> : null
        ),
        cellStyle: { textAlign: 'center' },
        headerClass: 'text-center',
      },
      {
        field: 'isPublished',
        headerName: 'Published',
        width: 80,
        cellRenderer: ({ value }) => (
          value ? <CheckIcon color="success" sx={{verticalAlign: 'middle'}} /> : null
        ),
        cellStyle: { textAlign: 'center' },
        headerClass: 'text-center',
      },
      {
        field: 'isPromoted',
        headerName: 'Promoted',
        width: 80,
        cellRenderer: ({ value }) => (
          value ? <CheckIcon color="success" sx={{verticalAlign: 'middle'}} /> : null
        ),
        cellStyle: { textAlign: 'center' },
        headerClass: 'text-center',
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        cellRenderer: ({ data }) => (
          <>
            <DataGridActionMenu>
              <MenuItem onClick={() => history.push(`slider/${data.id}`)}><EditIcon />Edit</MenuItem>
              <MenuItem onClick={() => onDuplicate(data)}><ContentCopyIcon />Duplicate</MenuItem>
              <MenuItem onClick={() => onDeleteSlide(data.id)}><DeleteForeverIcon/>Remove</MenuItem>
            </DataGridActionMenu>
          </>
        ),
        cellStyle: { textAlign: 'center' },
        headerClass: 'text-center'
      },
    ]
  }, [gamesById, history, onDeleteSlide, onDuplicate]);

  const filters = useMemo(() => [
      {
        field: 'site',
        label: 'Site',
        type: 'select',
        options: [
          {id: null, label: 'All'},
          {id: 'game', label: 'Game'},
          {id: 'tv', label: 'TV'},
          {id: 'store', label: 'Store'},
        ]
      },
      {
        field: 'lang',
        label: 'Language',
        type: 'language',
        value: null
      },
      {
        field: 'gameId',
        label: 'Game',
        type: 'game',
      },
    ], []);

  return <DataGridListPage
    columns={columns}
    filters={filters}
    apiEndpoint="slide"
    heading="Home Slides"
    hideSearchInput
    pageKey={pageKeys.slideList}
    onAdd={() => history.push(`/slider/new`)}
    onSaveRankings={onSaveRankings}
    rowsPerPage={50}
    initialQueryParams={{
      isAdmin: 1
    }}
  />
}

