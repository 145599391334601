import {
  GET_LANGUAGES_START,
  GET_LANGUAGES_FINISH,
  GET_LANGUAGES_ERROR,
  CHANGE_LANGUAGE
} from "../actionTypes/language";

const initialState = {
  currentLanguage: localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "en",
  languages: [],
  loading: false,
  err: null,
};

export default function (state = initialState, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case CHANGE_LANGUAGE:
      newState.currentLanguage = action.payload;
      localStorage.setItem("lang", action.payload);
      return newState;
    case GET_LANGUAGES_START:
      newState.loading = true;
      newState.err = null;
      return newState;
    case GET_LANGUAGES_FINISH:
      newState.loading = false;
      newState.err = null;
      newState.languages = action.payload;
      return newState;
    case GET_LANGUAGES_ERROR:
      newState.loading = false;
      newState.err = action.payload;
      return newState;
    default:
      return state;
  }
}
