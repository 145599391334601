import React from "react";
import Typography from "@mui/material/Typography";
import SessionStyles from "../../../styles/Session";
import { Link } from "react-router-dom";
import { Button, Box } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';


export const NotFound = () => {
  const classes = makeStyles(SessionStyles)();

  return (
    <div className={classes.session}>
      <div className={classes.content}>
        <Typography className={classes.title}>404</Typography>
        <Typography className={classes.subtitle}>Page not found!</Typography>
        <Typography variant="caption">
          Sorry, but the page you were trying to view does not exist.
        </Typography>
        <Box mt={2}>
          <Button component={Link} to="/" variant="contained" color="primary">
            Go To Dashboard
        </Button>
        </Box>
      </div>
    </div>
  );
};
