import { DataGridListPage, DataGridActionMenu } from 'pages/Common';
import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { MenuItem } from '@mui/material';
import { pageKeys } from 'store/reducers/dataGrid.reducer';
import { useHistory } from 'react-router-dom';
import { Edit as EditIcon, DeleteForever as DeleteForeverIcon } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { deleteDataGridRow } from 'store/actions';


export const PremiumOrder = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onDelete = useCallback((orderId) => {
    const confirmed = window.confirm("Are you sure you want to delete this order permanently?");
    if (confirmed) {
      dispatch(deleteDataGridRow(`order`, orderId, {}, {}, pageKeys.orderList));
    }
  }, [dispatch]);

  const columns = useMemo(() => {
    return [
      {
        field: 'id',
        headerName: 'Id',
        width: 80
      },
      {
        field: 'orderRef',
        headerName: 'OrderRef',
        width: 200,
      },
      {
        headerName: 'User Name',
        valueFormatter: ({ data }) => `${data.firstName} ${data.lastName}`,
        width: 180,
      },
      {
        field: 'startTime',
        headerName: 'Start Time',
        valueFormatter: ({ value }) => value && moment(value).format('L'),
        width: 100,
      },
      {
        field: 'endTime',
        headerName: 'End Time',
        valueFormatter: ({ value }) => value && moment(value).format('L'),
        width: 100,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 100,
      },
      {
        field: 'notification',
        headerName: 'Notification',
        flex: 1,
      },
      {
        field: 'source',
        headerName: 'Source',
        width: 100,
        flex: 1,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        cellStyle: { textAlign: 'center' },
        headerClass: 'text-center',
        cellRenderer: ({ data }) => (
          <DataGridActionMenu>
            <MenuItem onClick={() => history.push(`/premium-order/${data.id}`)}><EditIcon />Edit</MenuItem>
            {data.source === 'admin' &&
            
            <MenuItem onClick={() => onDelete(data.id)}><DeleteForeverIcon />Remove</MenuItem>
            }
          </DataGridActionMenu>
        ),
      },
    ];
  }, [history, onDelete]);

  const filters = [
    {
      field: 'userId',
      label: 'User',
      type: 'user',
    },
    {
      field: 'source',
      label: 'Source',
      type: 'select',
      options: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'pabbly',
          label: 'Pabbly'
        },
        {
          id: 'legacy',
          label: 'Legacy'
        },
        {
          id: 'admin',
          label: 'Admin'
        },
      ]
    },
  ];

  return <DataGridListPage
    columns={columns}
    filters={filters}
    apiEndpoint="order"
    searchKey="orderRef"
    searchPlaceholder="Order Ref"
    heading="Premium Orders"
    pageKey={pageKeys.orderList}
    onAdd={() => history.push(`/premium-order/new`)}
  />
}

