import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle, Box
} from '@mui/material';
import { DynamicFormInputs } from 'pages/Common/DynamicFormInputs';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addDataGridRow, editDataGridRow } from 'store/actions';
import { pageKeys } from 'store/reducers/dataGrid.reducer';

const getInputValue = (data, name) => {
  return data && data[name] ? data[name] : ''
}

export const StreamAddEdit = ({ stream, duplicate, eventId, open, onClose }) => {
  const [data, setData] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    setData(stream);
  }, [stream]);

  const inputs = useMemo(() => [
    {
      field: 'label',
      label: 'Label',
      type: 'text',
      value: getInputValue(data, 'label')
    },
    {
      field: 'level',
      label: 'Level',
      type: 'text',
      value: getInputValue(data, 'level')
    },
    {
      field: 'name',
      label: 'Name',
      type: 'text',
      value: getInputValue(data, 'name')
    },
    {
      field: 'rank',
      label: 'Rank',
      type: 'number',
      value: getInputValue(data, 'rank')
    },
    {
      field: 'token',
      label: 'Token',
      type: 'text',
      value: getInputValue(data, 'token')
    },
    {
      field: 'server',
      label: 'Server',
      type: 'text',
      value: getInputValue(data, 'server')
    },
    {
      field: 'delay',
      label: 'Delay',
      type: 'text',
      value: getInputValue(data, 'delay')
    },
  ], [data]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (data && !data.rank) {
      data.rank = null;
    }
    if (data) {
      delete data.createdAt;
      delete data.updatedAt;
      delete data.event;
    }
    if (stream && !duplicate) {
      dispatch(editDataGridRow('stream', stream.id, data, {}, pageKeys.eventStreams));
    } else {
      dispatch(addDataGridRow('stream', { ...data, eventId }, {}, pageKeys.eventStreams));
    }
    onClose();
  }

  return (
    <Dialog onClose={onClose} maxWidth="md" fullWidth open={open}>
      <form onSubmit={onSubmit}>
        <DialogTitle>{duplicate ? 'Duplicate' : stream ? 'Edit' : 'Add'} Stream</DialogTitle>
        <DialogContent>
          <Box py={2}>
            <DynamicFormInputs inputs={inputs} onChange={(value) => setData({...data, ...value})}/>
            {data &&
            <Box mt={2}>
              URL: {data.url}
            </Box>
            }
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

