import { RemoteSourceAutoComplete } from 'components/RemoteSourceAutoComplete';
import React from 'react';

export const EventTypeSelect = ({ onChange, eventTypeId, disabled = false, placeholder, params = {} }) => {
  return (
    <RemoteSourceAutoComplete
      apiPath="eventType"
      queryParams={params}
      searchKey="searchStr"
      selectedId={eventTypeId}
      disabled={disabled}
      getOptionLabel={(option => `${option.id} - ${option.name}`)}
      onChange={onChange}
      placeholder={placeholder || "Event Type"}
    />
  )
};
