import {
  Box,
  CircularProgress,
  Paper,
} from "@mui/material";
import { DynamicFormInputs, useDetailPage, DetailHeading } from "pages/Common";
import React, { useMemo } from "react";

export const UserDetail = () => {
  const {
    data,
    loading,
    saving,
    onSave,
    onUpdateData,
  } = useDetailPage({
    apiEndpoint: 'user',
  });

  const mainInputs = useMemo(() => {
    if (!data) {
      return [];
    }
    return [
      {
        field: 'firstName',
        label: 'First Name',
        type: 'text',
        value: data.firstName
      },
      {
        field: 'lastName',
        label: 'Last Name',
        type: 'text',
        value: data.lastName
      },
    ]
  }, [data]);

  return (
    <Paper>
      <Box p={2}>
        {loading &&
          <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        {!!data &&
          <>
            <DetailHeading
              listLink="/user"
              listName="Users"
              title={`${data.firstName} ${data.lastName}`}
              saving={saving}
              onSave={onSave}
            />
            <Paper sx={{ padding: 2 }}>
              <DynamicFormInputs inputs={mainInputs} onChange={(value) => onUpdateData(value)} />
            </Paper>
          </>
        }
      </Box>
    </Paper>
  )
};
