import { RemoteSourceAutoComplete } from 'components/RemoteSourceAutoComplete';
import React from 'react';

export const PlayerSelect = ({ onChange, playerId, disabled = false, placeholder, params = {} }) => {
  return (
    <RemoteSourceAutoComplete
      apiPath='player'
      queryParams={{
        ...params,
        limit: 50
      }}
      selectedId={playerId}
      disabled={disabled}
      getOptionLabel={(option => `${option.firstName} ${option.lastName} (${option.id})`)}
      onChange={onChange}
      placeholder={placeholder}
    />
  )
};
