import {
  Check as CheckIcon,
  PlaylistPlay as PlaylistPlayIcon,
  Edit as EditIcon,
  Star as StarIcon,
  StarOutline as StarOutlineIcon,
  EventNoteOutlined as EventNoteOutlinedIcon,
  DeleteForever as DeleteForeverIcon,
  MilitaryTech as MilitaryTechIcon
} from '@mui/icons-material';

import moment from 'moment';
import { DataGridListPage, DataGridActionMenu } from 'pages/Common';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getGameName } from 'utils';
import { IconButton, MenuItem } from '@mui/material';
import { pageKeys } from 'store/reducers/dataGrid.reducer';
import { omit } from 'lodash';
import { getBookmarkedEvents } from 'utils/event';
import { deleteDataGridRow } from 'store/actions';

export const EventList = () => {
  const gamesById = useSelector(state => state.games.gamesById);
  const history = useHistory();
  const dispatch = useDispatch();
  const [bookMarkedEvents, setBookmarkedEvents] = useState([]);

  useEffect(() => {
    setBookmarkedEvents(getBookmarkedEvents());
  }, []);
  
  const addToBookmarks = useCallback((event) => {
    const events = [omit(event, ['additionalInfo', 'image', 'totalVideoDuration', 'typeId', 'videoCount', 'level']), ...bookMarkedEvents];
    window.localStorage.setItem('bookMarkedEvents', JSON.stringify(events));
    setBookmarkedEvents(events);
  }, [bookMarkedEvents]);

  const removeFromBookmarks = useCallback((event) => {
    const events = bookMarkedEvents.filter(item => item.id !== event.id);
    window.localStorage.setItem('bookMarkedEvents', JSON.stringify(events));
    setBookmarkedEvents(events);
  }, [bookMarkedEvents]);

  const onDelete = useCallback((eventId) => {
    const confirmed = window.confirm("Are you sure you want to delete this event permanently?");
    if (confirmed) {
      dispatch(deleteDataGridRow(`event`, eventId, {}, {}, pageKeys.eventList));
    }
  }, [dispatch]);

  const columns = useMemo(() => {

    return [
      {
        field: 'id',
        headerName: 'Id',
        width: 80,
      },
      {
        field: 'startTime',
        headerName: 'Start Time',
        valueFormatter: ({ value }) => value && moment(value).format('Y-MM-DD'),
        width: 120,
      },
      {
        field: 'endTime',
        headerName: 'End Time',
        valueFormatter: ({ value }) => value && moment(value).format('Y-MM-DD'),
        width: 120,
      },
      {
        field: 'gameId',
        headerName: 'Game',
        valueFormatter: ({ value }) => getGameName(gamesById, value),
        width: 120,
      },
      {
        field: 'eventTypeName',
        headerName: 'Event Type',
        flex: 1,
      },
      {
        field: 'location',
        headerName: 'Location',
        valueGetter: ({ data }) => `${data.city}, ${data.countryIso}`,
        width: 150,
      },
      {
        field: 'isLive',
        headerName: 'Is Live',
        cellRenderer: ({ value }) => (
          value ? <CheckIcon color="success" sx={{verticalAlign: 'middle'}} /> : null
        ),
        cellStyle: { textAlign: 'center' },
        headerClass: 'text-center',
        width: 80,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 120,
        cellRenderer: ({ data }) => (
          <>
            {bookMarkedEvents.find(item => item.id === data.id) ?
              <IconButton title="Remove from Bookmarks" onClick={() => removeFromBookmarks(data)}>
                <StarIcon />
              </IconButton>
              :
              <IconButton title="Add to Bookmarks" onClick={() => addToBookmarks(data)}>
                <StarOutlineIcon />
              </IconButton>
            }
            <DataGridActionMenu>
              <MenuItem onClick={() => history.push(`/event/${data.id}`)}><EditIcon />Edit</MenuItem>
              <MenuItem onClick={() => history.push(`/eventPlaylist/${data.id}`)}><PlaylistPlayIcon />Edit Playlist</MenuItem>
              <MenuItem onClick={() => history.push(`/eventSchedule/${data.id}`)}><EventNoteOutlinedIcon />Edit Schedule</MenuItem>
              <MenuItem onClick={() => history.push(`/eventStreams/${data.id}`)}><EventNoteOutlinedIcon />Edit Streams</MenuItem>
              <MenuItem onClick={() => history.push(`/rankings/${data.id}`)}><MilitaryTechIcon />Edit Rankings</MenuItem>
              <MenuItem onClick={() => onDelete(data.id)}><DeleteForeverIcon />Delete Event</MenuItem>
            </DataGridActionMenu>
          </>
        ),
        cellStyle: { textAlign: 'center' },
        headerClass: 'text-center'
      },
    ]
  }, [gamesById, history, addToBookmarks, removeFromBookmarks, onDelete, bookMarkedEvents]);

  const filters = useMemo(() => [
      {
        field: 'startAfter',
        label: 'Start After',
        type: 'date',
        value: null
      },
      {
        field: 'endBefore',
        label: 'End Before',
        type: 'date',
        value: null
      },
      {
        field: 'sportId',
        label: 'Sport',
        type: 'sport',
        value: null
      },
      {
        field: 'isLive',
        label: 'Is Live',
        type: 'switch',
        dataType: 'int',
        value: 0
      },
      {
        field: 'isLiveNow',
        label: 'Is Live Now',
        type: 'switch',
        dataType: 'int',
        value: 0
      },
      {
        field: 'hasVideosOnly',
        label: 'Has Videos Only',
        type: 'switch',
        dataType: 'int',
        value: 0
      },
    ], []);

  return <DataGridListPage
    columns={columns}
    filters={filters}
    apiEndpoint="event"
    heading="Events"
    pageKey={pageKeys.eventList}
    onAdd={() => history.push(`/event/new`)}
    rowsPerPage={50}
    initialQueryParams={{
      orderBy: 'startTime',
      order: 'DESC',
      isAdmin: 1,
    }}
  />
}

