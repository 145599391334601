import {
  Box,
  CircularProgress,
  Grid, Paper,
} from "@mui/material";
import { MediaUpload } from "components/MediaUpload";
import { useDetailPage, DetailHeading, DynamicFormInputs } from "pages/Common";
import React, { useMemo } from "react";

export const SlideDetail = () => {
  const {
    id,
    data,
    loading,
    saving,
    onSave,
    onUpdateData,
  } = useDetailPage({
    apiEndpoint: 'slide',
    defaultData
  });

  const mainInputs = useMemo(() => {
    if (!data) {
      return [];
    }
    return [
      {
        field: 'gameId',
        label: 'Game *',
        type: 'game',
        value: data.gameId
      },
      {
        field: 'sportId',
        label: 'Sport *',
        type: 'sport',
        value: data.sportId
      },
      {
        field: 'site',
        label: 'Site *',
        type: 'select',
        value: data.site,
        options: [
          {id: 'game', label: 'Game'},
          {id: 'tv', label: 'TV'},
          {id: 'store', label: 'Store'},
        ]
      },
      {
      field: 'lang',
        label: 'Language *',
        type: 'language',
        value: data.lang,
      },
      {
        field: 'title',
        label: 'Title',
        type: 'text',
        value: data.title
      },
      {
        field: 'link',
        label: 'Link *',
        type: 'text',
        value: data.link
      },
      {
        field: 'startTime',
        label: 'Start *',
        type: 'datetime',
        value: data.startTime,
      },
      {
        field: 'endTime',
        label: 'End *',
        type: 'datetime',
        value: data.endTime,
      },
      {
        field: 'storeTag',
        label: 'Store Tag',
        type: 'text',
        value: data.storeTag,
      },
      {
        field: 'isLive',
        label: 'Is On Air',
        type: 'switch',
        value: data.isLive,
      },
      {
        field: 'isPublished',
        label: 'Published',
        type: 'switch',
        value: data.isPublished,
      },
      {
        field: 'isPromoted',
        label: 'Promoted',
        type: 'switch',
        value: data.isPromoted,
      },
    ]
  }, [data]);

  return (
    <Paper>
      <Box p={2}>
        {loading &&
          <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        {!!data &&
          <>
            <DetailHeading
              listLink="/slider"
              listName="Sliders"
              title={id === 'new' ? "New Slide" : data.title}
              saving={saving}
              onSave={onSave}
              />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={7}>
                <Paper sx={{ padding: 2 }}>
                  <DynamicFormInputs inputs={mainInputs} onChange={(value) => onUpdateData(value)} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <MediaUpload
                  component="img"
                  label="Thumbnail"
                  fileUrl={data.image}
                  onUploaded={(image) => onUpdateData({ image })}
                />
              </Grid>
            </Grid>
          </>
        }
      </Box>
    </Paper>
  )
};


const defaultData = {
  site: null,
  slideOrder: 0,
  image: null,
  title: null,
  link: null,
  isPublished: false,
  isLive: false,
  isPromoted: false,
  startTime: new Date(),
  endTime: new Date(),
  lang: 'en',
  gameId: null,
  sportId: null
}