import { getGameName } from "utils";
import moment from "moment";

/**
 * Get bookmarked events stored in localStorage
 */
export const getBookmarkedEvents = () => {
  try {
    const events = JSON.parse(window.localStorage.getItem('bookMarkedEvents'));
    if (Array.isArray(events)) {
      return events.map(item => ({...item, isBookmarked: true}));
    } else {
      window.localStorage.setItem('bookMarkedEvents', '[]');
      return [];
    }
  } catch (error) {
    return []
  }
}

export const getEventName = (event, gamesById) => {
  if (!event.id) {
    return '';
  }
  return `${event.id}, ${getGameName(gamesById, event.gameId)}, ${event.eventTypeName}, ${event.city}, ${moment(event.startTime).format("L")} - ${moment(event.endTime).format("L")}`;
}