import { drawerWidth } from "./variables";

const WorkspaceStyles = (theme) => ({
  content: {
    backgroundColor: "#e0e0e2",
    minWidth: 0,
    width: "100%",
    position: "relative",
    display: "block",
    [theme.breakpoints.up("sm")]: {
      overflowY: "auto",
      overflowX: "hidden",
    },
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "-webkit-overflow-scrolling": "touch",
  },
  paper: {
    minHeight: "100%",
  },
  "content-left": {
    [theme.breakpoints.up("md")]: {
      marginLeft: -drawerWidth,
    },
  },
  "content-right": {
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  "contentShift-left": {
    marginLeft: 0,
  },
  "contentShift-right": {
    marginRight: 0,
  },
});

export default WorkspaceStyles;
