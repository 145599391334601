import { drawerWidth } from "../../variables";

const SidebarStyles = (theme) => ({
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
    maxWidth: drawerWidth,
    height: "100%",
    zIndex: theme.zIndex.drawer + 99,
    background: "#302d2b",
  },
  modal: {
    [theme.breakpoints.down('md')]: {
      top: "56px",
    },
    [theme.breakpoints.up("sm")]: {
      top: "64px",
    },
    zIndex: "1000",
  },
  backdrop: {
    [theme.breakpoints.down('md')]: {
      top: "56px",
    },
    [theme.breakpoints.up("sm")]: {
      top: "64px",
    },
  },
});

export default SidebarStyles;
