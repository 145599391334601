import {
  GET_GAMES_START,
  GET_GAMES_FINISH,
  GET_GAMES_ERROR,
} from "../actionTypes/game";

const initialState = {
  loading: false,
  data: [],
  gamesById: {},
  err: null,
};

export default function (state = initialState, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case GET_GAMES_START:
      newState.loading = true;
      newState.err = null;
      return newState;
    case GET_GAMES_FINISH:
      newState.loading = false;
      if (Array.isArray(action.payload)) {
        const gamesById = {};
        action.payload.forEach(game => {
          gamesById[game.id] = game;
        });
        newState.gamesById = gamesById;
        newState.data = action.payload;
      }

      newState.err = null;
      return newState;
    case GET_GAMES_ERROR:
      newState.loading = false;
      newState.err = action.payload;
      return newState;
    default:
      return newState;
  }
}
