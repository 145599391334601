import { GET_CURRENT_USER_FAILURE, GET_CURRENT_USER_SUCCESS, NEED_RELOGIN, GET_CURRENT_USER, LOGOUT_SUCCESS } from "store/actionTypes/auth";

const initialState = {
  loading: false,
  userInfo: null,
  error: null
};

export default function (state = initialState, action) {
  switch (action.type) {
  case GET_CURRENT_USER:
    return {
      loading: true,
      error: null,
      userInfo: null
    };
  case GET_CURRENT_USER_SUCCESS:
    return {
      loading: false,
      error: null,
      userInfo: action.payload.userInfo
    };
  case GET_CURRENT_USER_FAILURE:
    return {
      loading: false,
      error: action.payload.error,
      userInfo: null
    };
  case NEED_RELOGIN:
    return {
      ...state,
      loading: false,
      userInfo: null
    };
  case LOGOUT_SUCCESS:
    return initialState;
  default:
    return state;
  }
}
