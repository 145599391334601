import {
  Box, CircularProgress, Grid, Paper
} from "@mui/material";
import { MediaUpload, TranslationEditor } from "components";
import { DynamicFormInputs, useDetailPage, DetailHeading } from "pages/Common";
import React, { useMemo } from "react";

export const GameDetail = () => {
  const {
    id,
    data,
    loading,
    saving,
    onSave,
    onUpdateData,
  } = useDetailPage({
    apiEndpoint: 'game',
    defaultData
  });
  const name = data && data.i18n ? data.i18n.find(item => item.lang === 'en').label : null;

  const mainInputs = useMemo(() => {
    if (!data) {
      return [];
    }
    return [
      {
        field: 'sportId',
        label: 'Sport',
        type: 'sport',
        value: data.sportId
      },
    ]
  }, [data]);

  return (
    <Paper>
      <Box p={2}>
        {loading &&
          <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        {!!data &&
          <>
            <DetailHeading
              listLink="/game"
              listName="Games"
              title={id === 'new' ? "New Game" : name}
              saving={saving}
              onSave={onSave}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <Paper sx={{ padding: 2 }}>
                  <DynamicFormInputs inputs={mainInputs} fullWidth={true} onChange={(value) => onUpdateData(value)} />
                  <br/>
                  <Box sx={{maxWidth: 300}}>
                    <MediaUpload
                      component="img"
                      label="Icon"
                      width="50"
                      fileUrl={data.icon}
                      onUploaded={(icon) => onUpdateData({ icon })}
                    />
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MediaUpload
                  component="img"
                  label="Banner"
                  fileUrl={data.bannerImage}
                  onUploaded={(bannerImage) => onUpdateData({ bannerImage })}
                />
              </Grid>
            </Grid>
            <Paper sx={{ padding: 2, marginTop: 2 }}>
              <TranslationEditor
                i18n={data.i18n}
                inputs={[
                  {field: 'label', type: 'text', label: 'Name', fullWidth: false},
                  {field: 'slug', type: 'text', label: 'Slug', fullWidth: false},
                ]}
                onUpdate={(i18n) => onUpdateData({i18n})}
              />
            </Paper>
          </>
        }
      </Box>
    </Paper>
  )
};

const defaultData = {
  rank: 0,
  sportId: 1
}