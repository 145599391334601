import {
  Box,
  CircularProgress,
  Paper,
} from "@mui/material";
import { TranslationEditor } from "components";
import { DynamicFormInputs, useDetailPage, DetailHeading } from "pages/Common";
import React, { useMemo } from "react";

export const EquipmentDetail = () => {
  const {
    id,
    data,
    loading,
    saving,
    onSave,
    onUpdateData,
  } = useDetailPage({
    apiEndpoint: 'equipment',
  });

  const mainInputs = useMemo(() => {
    if (!data) {
      return [];
    }
    return [
      {
        field: 'sportId',
        label: 'Sport',
        type: 'sport',
        value: data.sportId
      },
      {
        field: 'marque',
        label: 'Marque',
        type: 'text',
        value: data.marque
      },
      {
        field: 'equipmentTypeId',
        label: 'Equipment Type',
        type: 'remoteSource',
        apiPath: 'equipmentType',
        value: data.equipmentTypeId,
        getOptionLabel: (option) => option.label,
      },
    ]
  }, [data]);

  return (
    <Paper>
      <Box p={2}>
        {loading &&
          <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        {!!data &&
          <>
            <DetailHeading
              listLink="/equipment"
              listName="Equipments"
              title={id === 'new' ? "New Equipment" : id}
              saving={saving}
              onSave={onSave}
            />
            <Paper sx={{ padding: 2 }}>
              <DynamicFormInputs inputs={mainInputs} onChange={(value) => onUpdateData(value)} />
              <TranslationEditor
                i18n={data.i18n}

                inputs={[
                  {field: 'storeUrl', type: 'text', label: 'Store Url'},
                ]}
                onUpdate={(i18n) => onUpdateData({i18n})}
              />
            </Paper>
          </>
        }
      </Box>
    </Paper>
  )
};
