import {
  ACTION_GET_MEDIA_LIBRARY_START,
  ACTION_GET_MEDIA_LIBRARY_FINISH,
  ACTION_GET_MEDIA_LIBRARY_ERROR,
  ACTION_UPLOAD_IMAGE_SUCCESSED,
  ACTION_UPLOAD_IMAGE_ERROR,
} from "store/actionTypes/actionMediaLibrary";

const initialState = {
  loading: false,
  dir: "/",
  data: [],
  err: null,
  uploadError: -1,
  uploadedImage: "",
};

export default function (state = initialState, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case ACTION_GET_MEDIA_LIBRARY_START:
      newState.loading = true;
      newState.err = null;
      newState.dir = action.dir === "/" ? "" : action.dir;
      localStorage.setItem("mediaLibrary_path", newState.dir);
      newState.uploadError = -1;
      return newState;
    case ACTION_GET_MEDIA_LIBRARY_FINISH:
      newState.loading = false;
      newState.dir = action.payload.dir;
      if (Array.isArray(action.payload.entries))
        newState.data = action.payload.entries;
      newState.err = null;
      newState.uploadError = -1;
      return newState;
    case ACTION_GET_MEDIA_LIBRARY_ERROR:
      newState.loading = false;
      newState.err = action.payload;
      newState.uploadError = -1;
      return newState;
    case ACTION_UPLOAD_IMAGE_SUCCESSED:
      newState.loading = false;
      newState.err = null;
      newState.uploadError = 0;
      newState.uploadedImage = action.link;
      return newState;
    case ACTION_UPLOAD_IMAGE_ERROR:
      newState.loading = false;
      newState.err = null;
      newState.uploadError = 1;
      return newState;
    default:
      return newState;
  }
}
