import React from "react";
import { Provider } from "react-redux";
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AppProvider } from "./components/AppProvider/AppProvider";
import Dashboard from "./containers/Dashboard";
import { Signin } from "./pages";
import { NotFound } from "./pages/Common";
import { unregister } from "./registerServiceWorker";
import { store } from "./store";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { msalInstance } from "auth";

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';

import "react-toastify/dist/ReactToastify.css";

ReactDOM.createRoot( 
  document.querySelector('#root')
).render(
  <Provider store={store}>
    <AppProvider pca={msalInstance}>
      <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
        <BrowserRouter basename="/">
          <Switch>
            <Route
              exact
              path="/login"
              component={(props) => <Signin {...props} />}
            />
            <Route
              exact
              path="/404"
              component={(props) => <NotFound {...props} />}
            />
            <Route
              path="/"
              component={(props) => <Dashboard {...props} />}
            />
            <Redirect to="/404" />
          </Switch>
        </BrowserRouter>
      </LocalizationProvider>
      <ToastContainer />
    </AppProvider>
  </Provider>

)
unregister();