import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

export const LanguageSelect = ({ onChange, lang, placeholder="Language" }) => {
  const langState = useSelector(state => state.lang);
  const labelId = placeholder.toLocaleLowerCase().replace(/\s+/g, '-');
  return (
    <FormControl size="small" fullWidth>
      <InputLabel id={labelId}>{placeholder}</InputLabel>
      <Select
        value={lang}
        labelId={labelId}
        label={placeholder}
        onChange={(e) => onChange(e.target.value)}
      >
        <MenuItem key={'all'} value={null}>All</MenuItem>
        {Array.isArray(langState.languages) &&
          langState.languages.map((l, index) => (
            <MenuItem key={index} value={l.iso}>
              {l.iso}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
};
