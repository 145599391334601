import { combineReducers } from "redux";
import * as ADMIN from "./Admin";
import auth from "./auth.reducer";
import countries from "./countries";
import dataGrid from "./dataGrid.reducer";
import games from "./games";
import lang from "./lang";
import sports from "./sports";
import statistics from "./statistics";


const rootReducer = combineReducers({
  ...ADMIN,
  statistics,
  countries,
  lang,
  sports,
  games,
  dataGrid,
  auth
});

export default rootReducer;
