import React from "react";
import { Popover } from "@mui/material";

export default class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { anchorEl, open, onClose, children } = this.props;
    return (
      <Popover anchorEl={anchorEl} open={open} onClose={onClose}>
        {children}
      </Popover>
    );
  }
}
