import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  IconButton,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Alert } from '@mui/material';

import {
  action_add_administrator,
  action_update_administrator,
} from "store/actions";

export const RoleList = [
  { id: "slider", label: "Slider" },
  null,
  { id: "event", label: "Event" },
  { id: "player", label: "Player" },
  null,
  { id: "news", label: "News" },
  { id: "news_lang", label: "Languages", type: "lang", invisibleOnList: true },
  null,
  { id: "channel", label: "Channel" },
  { id: "live_replay", label: "Live & Replay" },
  { id: "masterclass", label: "Masterclass" },
  null,
  { id: "instruction", label: "Instruction" },
  null,
  { id: "storage", label: "Storage" },
  null,
  { id: "setting", label: "Setting" },
  null,
  { id: "member", label: "Member" },
  { id: "admin", label: "Admin" },
];

export const LanguageRoleList = [{ id: "news_lang", label: "News Language" }];

export const Roles = [
  { label: "None", value: null },
  { label: "Read", value: 2 },
  { label: "Read / Write", value: 3 },
];

class ManageDialog extends React.Component {
  constructor(props) {
    super(props);
    let role = "{}";
    let roleJson = {};
    if (props.data && props.data.role) {
      role = props.data.role;
      try {
        roleJson = JSON.parse(role);
      } catch (_) {
        roleJson = {};
      }
    }
    this.state = {
      isNew: props.isNew,
      ...props.data,
      roleJson,
    };
  }
  handleClose = () => {
    this.props.onClose();
  };

  handleSave = () => {
    let { isNew, id, email, roleJson } = this.state;

    const data = {
      id,
      email,
      role: JSON.stringify(roleJson),
    };
    !isNew && this.props.action_update_administrator(data);
    isNew && this.props.action_add_administrator(data);
  };

  UNSAFE_componentWillReceiveProps = (newProps) => {
    // check upload state
    const { adminList } = newProps;
    const newUploadError = adminList.uploadError;
    const prevUploadError =
      this.props.adminList && this.props.adminList.uploadError;

    if (prevUploadError === -1 && newUploadError >= 0) {
      this.setState({ alertOpened: true, alertType: newUploadError });
    }
    // end check upload state
  };

  _renderAlert = () => {
    const { isNew, alertOpened, alertType } = this.state;
    const { adminList } = this.props;
    return (
      <div>
        <Collapse in={alertOpened}>
          <Alert
            variant="filled"
            severity={alertType === 0 ? "success" : "error"}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => this.setState({ alertOpened: false })}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {typeof adminList.uploadErrorDetail === "string"
              ? adminList.uploadErrorDetail
              : alertType === 0
              ? isNew
                ? "Add Successed"
                : "Update Successed"
              : isNew
              ? "Add Failed"
              : "Update Failed"}
          </Alert>
        </Collapse>
      </div>
    );
  };

  render() {
    const { isNew, email, roleJson } = this.state;

    const { lang } = this.props;

    return (
      <Dialog
        open={true}
        onClose={this.handleClose}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {isNew ? "Add Administrator" : "Edit Administrator"}
        </DialogTitle>
        <DialogContent>
          {this._renderAlert()}
          <div>
            <TextField
              variant="outlined"
              size="small"
              margin="normal"
              label="Email"
              fullWidth
              value={email}
              onChange={(e) => this.setState({ email: e.target.value })}
            />
          </div>
          <Divider />
          <Typography variant="h6">Permissions</Typography>
          <div>
            {RoleList.map((item, index) => {
              if (!item) return <Divider key={index} />;
              if (item.type === "lang") {
                return (
                  <div key={index}>
                    {lang.languages.map((language, idx) => (
                      <FormControlLabel
                        key={idx}
                        control={
                          <Switch
                            color="primary"
                            checked={
                              Array.isArray(roleJson[item.id])
                                ? roleJson[item.id].includes(language.iso)
                                : false
                            }
                            onClick={() => {
                              let newArray = [];
                              if (Array.isArray(roleJson[item.id]))
                                newArray = [...roleJson[item.id]];
                              if (!newArray.includes(language.iso))
                                newArray.push(language.iso);
                              else {
                                let i = newArray.indexOf(language.iso);
                                newArray.splice(i, 1);
                              }
                              this.setState({
                                roleJson: { ...roleJson, [item.id]: newArray },
                              });
                            }}
                          />
                        }
                        label={language.label}
                      />
                    ))}
                  </div>
                );
              }
              return (
                <FormControl
                  variant="outlined"
                  size="small"
                  key={index}
                  margin="normal"
                  style={{ minWidth: 150, marginLeft: 10, marginRight: 10 }}
                >
                  <InputLabel>{item.label}</InputLabel>
                  <Select
                    value={roleJson[item.id]}
                    onChange={(e) =>
                      this.setState({
                        roleJson: { ...roleJson, [item.id]: e.target.value },
                      })
                    }
                  >
                    {Roles.map((role, idx) => (
                      <MenuItem key={idx} value={role.value}>
                        {role.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            })}
          </div>
          <DialogActions>
            <Button variant="contained" onClick={this.handleSave}>
              Save
            </Button>
            <Button variant="contained" onClick={this.handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

ManageDialog.propTypes = {};

const mapStateToProps = (state) => {
  return {
    adminList: state.a_administrators,
    lang: state.lang,
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      action_add_administrator,
      action_update_administrator,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(ManageDialog);
