import React, { useEffect, useMemo, useState } from "react";
import { Autocomplete, createFilterOptions, debounce } from '@mui/material';
import { TextField, CircularProgress } from "@mui/material";
import PropTypes from 'prop-types';
import axios from "axios";
import { SERVER_URL } from "constants/serverUrl";
import { getAuthHeader } from "utils";
import { unionBy } from "lodash";

const localFilterOptions = createFilterOptions();


export const RemoteSourceAutoComplete = ({
  apiPath,
  searchKey = 'searchStr',
  localFilter = false, // by default, it should be remote filter. In some cases, API request returns all data, so localFilter is enough.
  getOptionLabel,
  placeholder,
  onChange,
  queryParams = {},
  selectedId,
  disabled,
  staticList = [],
}) => {
  const [searchStr, setSearchStr] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);

  const onSearch = debounce((str) => {
    if (!localFilter) {
      setSearchStr(str);
    }
  }, 300);

  const onChangeValue = (e, value) => {
    setValue(value);
    onChange(value);
  }

  useEffect(() => {
    setLoading(true);
    if (disabled && selectedId) {
      loadSelectedItem();
      return;
    }
    axios.get(`${SERVER_URL}/${apiPath}`, {
      params: {[searchKey]: searchStr, ...queryParams},
      headers: getAuthHeader()
    }).then(response => {
      setOptions(response.data);
      if (selectedId) {
        const selectedItem = response.data.find(item => item.id === selectedId);
        if (selectedItem) {
          setValue(selectedItem);
          setLoading(false);
        } else {
          loadSelectedItem();
        }
      } else {
        setLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStr]);

  const loadSelectedItem = () => {
    axios.get(`${SERVER_URL}/${apiPath}/${selectedId}`, {headers: getAuthHeader()}).then((res) => {
      setOptions([...options, res.data]);
      setValue(res.data);
      setLoading(false);
    });
  }

  const unionOptions = useMemo(() => {
    return unionBy(staticList, options, 'id');
  }, [staticList, options])

  return (
    <Autocomplete
      fullWidth
      disabled={disabled}
      options={unionOptions}
      autoHighlight
      getOptionLabel={getOptionLabel}
      onChange={onChangeValue}
      filterOptions={localFilter ? localFilterOptions : (x) => x}
      value={value}
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => onSearch(e.target.value)}
          label={placeholder || 'Select One'}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} sx={{marginRight: 1}} /> : params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

RemoteSourceAutoComplete.propTypes = {
  apiPath: PropTypes.string.isRequired,
  searchKey: PropTypes.string,
  getOptionLabel: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}