import { MsalProvider } from "@azure/msal-react";
import { indigo, red } from "@mui/material/colors";
import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";

export const AppContext = React.createContext();

export const AppProvider = ({ children, pca }) => {
  const [state, setState] = useState({
    mode: "light",
    direction: "ltr",
    bLanguageDialogOpened: false,
  });

  const theme = createTheme({
    direction: state.direction,
    palette: {
      mode: state.mode,
      primary: indigo,
      secondary: red,
      error: red,
    },
    typography: {
      fontFamily: 'Roboto,"Helvetica Neue",Arial,sans-serif',
      headline: {
        fontSize: "1rem",
      },
      subheading: {
        fontSize: "0.8125rem",
      },
      button: {
        fontWeight: 400,
        textTransform: "initial",
      },
    },
    shape: {
      borderRadius: 4,
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MsalProvider instance={pca}>
          <AppContext.Provider
            value={{
              state,
              toggleTheme: () =>
                setState({
                  ...state,
                  type: this.state.type === "light" ? "dark" : "light",
                }),
              toggleLanguageDialog: () =>
                setState({ ...state, bLanguageDialogOpened: true }),
            }}
          >
            {children}
          </AppContext.Provider>
        </MsalProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

