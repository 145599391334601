import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Box
} from '@mui/material';
import { DynamicFormInputs } from 'pages/Common';

export const RankingAddEdit = ({ open, onClose, onSave, ranking, total }) => {
  const [data, setdata] = useState(null);
  useEffect(() => {
    setdata(ranking);
  }, [ranking]);
  const inputs = useMemo(() => {
    const arr = [
      {
        field: 'player',
        label: 'Player',
        type: 'player',
        value: ranking?.player?.id
      },
      {
        field: 'rank',
        label: 'Rank',
        type: 'number',
        value: ranking?.rank || total + 1
      }
    ];
    if (ranking) {
      arr.push(
        ...[{
          field: 'money',
          label: 'Prize Money',
          type: 'number',
          value: ranking?.money
        },
        {
          field: 'day',
          label: 'Day',
          type: 'number',
          value: ranking?.day
        },
        {
          field: 'won',
          label: 'Won',
          type: 'number',
          value: ranking?.won
        },
        {
          field: 'lost',
          label: 'Lost',
          type: 'number',
          value: ranking?.lost
        },
        {
          field: 'run',
          label: 'Run',
          type: 'number',
          value: ranking?.run
        },
        {
          field: 'points',
          label: 'Points',
          type: 'number',
          value: ranking?.points
        },
        {
          field: 'innings',
          label: 'Innings',
          type: 'number',
          value: ranking?.innings
        }]
      );
    }
    return arr;
  }, [ranking, total]);

  const onSubmit = (e) => {
    e.preventDefault();
    onSave(data);
    onClose();
  }

  return (
    <Dialog onClose={onClose} maxWidth="sm" fullWidth open={open}>
      <form onSubmit={onSubmit}>
        <DialogTitle>{ranking ? 'Edit Ranking' : 'Add Player'}</DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <DynamicFormInputs inputs={inputs} fullWidth={true} onChange={(value) => setdata({...data, ...value})} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
