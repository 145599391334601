import {
  GET_COUNTRIES_START,
  GET_COUNTRIES_FINISH,
  GET_COUNTRIES_ERROR,
} from "store/actionTypes/country";

import { SERVER_URL } from "constants/serverUrl";
import { getAccessToken } from "utils";
import axios from "axios";
const url = `${SERVER_URL}/country`;

export const getCountries = () => {
  return (dispatch) => {
    dispatch({
      type: GET_COUNTRIES_START,
    });
    axios
      .get(url, {
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_COUNTRIES_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_COUNTRIES_ERROR,
          payload: err,
        });
      });
  };
};
