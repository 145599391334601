export const ACTION_GET_ADMINISTRATORS_START =
  "action_get_administrators_start";
export const ACTION_GET_ADMINISTRATORS_FINISH =
  "action_get_administrators_finish";
export const ACTION_GET_ADMINISTRATORS_ERROR =
  "action_get_administrators_error";

export const ACTION_SAVE_ADMINISTRATOR_START =
  "action_save_administrator_sTART";
export const ACTION_SAVE_ADMINISTRATOR_SUCCESS =
  "action_save_administrator_success";
export const ACTION_SAVE_ADMINISTRATOR_ERROR =
  "action_save_administrator_error";
