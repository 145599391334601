export function downloadCSV(content, filename) {
  // Create a blob
  var blob = new Blob([content], { type: 'text/csv;charset=utf-8' });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
}