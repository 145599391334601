import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, IconButton, Popover, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { DynamicFormInputs } from './DynamicFormInputs';


/**
 * filters is filter configuration list. {field: string, label: string, type: string, value: any}[]
 */
export const FilterPopover = ({ filters, onFiltersChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Box ml={1}>
      <Tooltip title="Filter list">
        <IconButton
          aria-label="Filter list"
          onClick={(e) => setAnchorEl(e.currentTarget)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}
      >
        <Box p={3} sx={{ width: 500 }}>
          <DynamicFormInputs inputs={filters} onChange={onFiltersChange} showReset={true} />
        </Box>
      </Popover>
    </Box>
  );
}

