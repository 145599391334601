import {
  Edit as EditIcon,
  DeleteForever as DeleteForeverIcon,
} from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { DataGridActionMenu, DataGridListPage } from 'pages/Common';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteDataGridRow } from 'store/actions';
import { pageKeys } from 'store/reducers/dataGrid.reducer';


export const SportList = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onDelete = useCallback((sportId) => {
    const confirmed = window.confirm("Are you sure you want to delete this sport permanently?");
    if (confirmed) {
      dispatch(deleteDataGridRow(`sport`, sportId, {}, {}, pageKeys.sportList));
    }
  }, [dispatch]);
  
  const columns = useMemo(() => {

    return [
      {
        field: 'id',
        headerName: 'Id',
        width: 80,
      },
      {
        field: 'name',
        headerName: 'Name',
        width: 250,
      },
      {
        field: 'slug',
        headerName: 'Slug',
        flex: 1,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        cellRenderer: ({ data }) => (
          <>
            <DataGridActionMenu>
              <MenuItem onClick={() => history.push(`/sport/${data.id}`)}><EditIcon />Edit</MenuItem>
              <MenuItem onClick={() => onDelete(data.id)}><DeleteForeverIcon/>Remove</MenuItem>
            </DataGridActionMenu>
          </>
        ),
        cellStyle: { textAlign: 'center' },
        headerClass: 'text-center'
      },
    ]
  }, [history, onDelete]);

  const filters = useMemo(() => [
      {
        field: 'lang',
        label: 'Language',
        type: 'language',
        value: 'en'
      },
    ], []);

  return <DataGridListPage
    columns={columns}
    filters={filters}
    apiEndpoint="sport"
    heading="Sports"
    hideSearchInput
    pageKey={pageKeys.sportList}
    onAdd={() => history.push(`/sport/new`)}
    hasPagination={false}
  />
}

