export function getCookie(cname) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
/**
 * 
 * @param cname string
 * @param cvalue string
 * @param timeStamp TimeStamp value in miliseconds
 * @param domain string
 * @param path string
 */
export function setCookie(cname, cvalue, timeStamp, domain = "kozoom.com", path = "/") {
  const d = new Date(timeStamp);
  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=" + domain + ";path=" + path;
}


export function deleteCookie(name, domain, path = "/") {
  if (getCookie(name)) {
    document.cookie = name + "=" +
      ((path) ? ";path=" + path : "") +
      ((domain) ? ";domain=" + domain : "") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}