import { Edit as EditIcon, DeleteForever as DeleteForeverIcon } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import moment from 'moment';
import { DataGridActionMenu, DataGridListPage } from 'pages/Common';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addDataGridRow, deleteDataGridRow, editDataGridRow } from 'store/actions';
import { pageKeys } from 'store/reducers/dataGrid.reducer';
import { ConfigurationDialog } from './AddEdit';


export const ConfigurationList = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [editingConfig, setEditingConfig] = useState(null);
  const dispatch = useDispatch();
  const onSave = (data) => {
    if (editingConfig) {
      dispatch(editDataGridRow('configuration', editingConfig.id, data, {}, pageKeys.configurationList));
    } else {
      dispatch(addDataGridRow('configuration', data, {}, pageKeys.configurationList));
    }
  }

  const onAdd = () => {
    setEditingConfig(null);
    setOpenDialog(true);
  }

  const columns = useMemo(() => {

    const onDelete = (rowId) => {
      const confirmed = window.confirm('Are you sure you want to delte this configuration?');
      if (confirmed) {
        dispatch(deleteDataGridRow('configuration', rowId, {}, {}, pageKeys.configurationList));
      }
    }
    const onEdit = (data) => {
      setEditingConfig(data);
      setOpenDialog(true);
    }

    return [
      {
        field: 'configKey',
        headerName: 'Key',
        width: 150,
      },
      {
        field: 'description',
        headerName: 'Description',
        width: 250,
      },
      {
        field: 'lang',
        headerName: 'Lang',
        valueFormatter: ({ value }) => value ? value : 'All',
        width: 70,
      },
      {
        field: 'value',
        headerName: 'Value',
        flex: 1,
      },
      {
        field: 'updatedAt',
        headerName: 'Updated At',
        valueFormatter: ({ value }) => value && moment(value).format('Y-MM-DD'),
        width: 120,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        cellStyle: { textAlign: 'center' },
        headerClass: 'text-center',
        cellRenderer: ({ data }) => (
          <DataGridActionMenu>
            <MenuItem onClick={() => onEdit(data)}><EditIcon />Edit</MenuItem>
            <MenuItem onClick={() => onDelete(data.id)}><DeleteForeverIcon />Remove</MenuItem>
          </DataGridActionMenu>
        ),
      },
    ];
  }, [dispatch]);

  const filters = useMemo(() => [
    {
      field: 'lang',
      label: 'Language',
      type: 'language',
      value: 'en'
    },
  ], []);

  return <>
    <DataGridListPage
      columns={columns}
      filters={filters}
      apiEndpoint="configuration"
      heading="Configurations"
      hideSearchInput
      onAdd={onAdd}
      hasPagination={false}
      pageKey={pageKeys.configurationList}
    />
    <ConfigurationDialog
      open={openDialog} onClose={() => setOpenDialog(false)}
      onSave={onSave}
      configuration={editingConfig}
    />
  </>
}

