import {
  ACTION_GET_ADMINISTRATORS_START,
  ACTION_GET_ADMINISTRATORS_FINISH,
  ACTION_GET_ADMINISTRATORS_ERROR,
  ACTION_SAVE_ADMINISTRATOR_START,
  ACTION_SAVE_ADMINISTRATOR_SUCCESS,
  ACTION_SAVE_ADMINISTRATOR_ERROR,
} from "store/actionTypes/actionAdministrators";
import { getAccessToken } from "utils";
import { SERVER_URL } from "constants/serverUrl";
import axios from "axios";
import { NEED_RELOGIN } from "store/actionTypes/auth";

const url = `${SERVER_URL}/administrator/manage`;

export const action_get_administrators = () => {
  return (dispatch) => {
    dispatch({
      type: ACTION_GET_ADMINISTRATORS_START,
    });
    axios
      .get(url, {
        headers: {
          Authorization: getAccessToken()        },
      })
      .then((res) => {
        dispatch({
          type: ACTION_GET_ADMINISTRATORS_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          if (err.response && err.response.status === 401) {
            dispatch({
              type: NEED_RELOGIN,
            });
          }
          dispatch({
            type: ACTION_GET_ADMINISTRATORS_ERROR,
            payload: err.response.data.msg || err.response.data.err,
          });
        }
      });
  };
};

export const action_add_administrator = (data) => {
  return (dispatch) => {
    dispatch({ type: ACTION_SAVE_ADMINISTRATOR_START });
    axios
      .post(url, data, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then((res) => {
        dispatch({
          type: ACTION_SAVE_ADMINISTRATOR_SUCCESS,
        });
        dispatch(action_get_administrators());
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          dispatch({
            type: ACTION_SAVE_ADMINISTRATOR_ERROR,
            payload: err.response.data.msg || err.response.data.err,
          });
        }
      });
  };
};

export const action_update_administrator = (data) => {
  return (dispatch) => {
    dispatch({ type: ACTION_SAVE_ADMINISTRATOR_START });
    axios
      .patch(`${url}/${data.id}`, data, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then((res) => {
        dispatch({
          type: ACTION_SAVE_ADMINISTRATOR_SUCCESS,
        });
        dispatch(action_get_administrators());
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          dispatch({
            type: ACTION_SAVE_ADMINISTRATOR_ERROR,
            payload: err.response.data.msg || err.response.data.err,
          });
        }
      });
  };
};

export const action_delete_administrators = (idList) => {
  return (dispatch) => {
    idList = idList.join(",");
    axios
      .delete(`${url}/${idList}`, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then((res) => {
        dispatch(action_get_administrators());
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          dispatch({
            type: ACTION_GET_ADMINISTRATORS_ERROR,
            payload: err.response.data.msg || err.response.data.err,
          });
        }
      });
  };
};
