import React from "react";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from '@mui/x-date-pickers';

import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, CircularProgress, Button, TableSortLabel } from "@mui/material";
import { Box } from "@mui/system";
import DownloadIcon from '@mui/icons-material/Download';
import Axios from "axios";
import { SERVER_URL } from "constants/serverUrl";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { getAuthHeader } from "utils";
import { formatEventDate } from "utils/time";
import { jsonToCSV } from 'react-papaparse';
import { downloadCSV } from "utils/file";


export const AudienceReport = () => {
  const [start, setStart] = useState(moment().subtract(14, 'days').toDate());
  const [end, setEnd] = useState(new Date());
  const [reports, setReports] = useState([]);
  const [sortBy, setSortBy] = useState('user');
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (start && end) {
      setIsLoading(true);
      Axios.get(`${SERVER_URL}/audience_report`, { headers: getAuthHeader(), params: { start, end } }).then(res => {
        setReports(res.data.map(item => ({
          Id: item.eventId,
          Event: item.eventName,
          Time: `${formatEventDate(item.eventStartTime)} - ${formatEventDate(item.eventEndTime)}`,
          Session: item.sessionCount,
          User: item.userCount,
          "Total Duration (hours)": (item.totalDuration / 3600).toFixed(2)
        })));
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        console.warn(err);
      });
    }
  }, [start, end]);

  const downloadRawData = () => {
    setIsDownloading(true);
    Axios.get(`${SERVER_URL}/audience_report`, { headers: getAuthHeader(), params: { start, end, isRaw: 1 } }).then(res => {
      downloadCSV(jsonToCSV(res.data), 'raw-data.csv');
      setIsDownloading(false);
    }).catch(err => {
      setIsDownloading(false);
      console.warn(err);
    });
  }

  const exportCSV = () => {
    downloadCSV(jsonToCSV(reports), 'report.csv');
  }

  const sortReports = (sort) => {
    setSortBy(sort);
    setReports(reports.sort((a,b) => {
      if (sort === 'session') {
        return b.Session - a.Session;
      }
      if (sort === 'user') {
        return b.User - a.User;
      }
      if (sort === 'duration') {
        return b['Total Duration (hours)'] - a['Total Duration (hours)'];
      }
      return 0;
    }));
  }

  return (
    <Paper>
      <Box p={2}>
        <Typography variant="h6" mb={2}>
          Audience Report
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={5} sm={4} md={2}>
            <DatePicker
              fullWidth
              clearable={true}
              value={moment(start)}
              onChange={(value) => setStart(value)}
              label="Start"
              slotProps={{ textField: { size: 'small' } }}
            />
          </Grid>
          <Grid item xs={5} sm={4} md={2}>
            <DatePicker
              fullWidth
              clearable={true}
              value={moment(end)}
              onChange={(value) => setEnd(value)}
              label="End"
              slotProps={{ textField: { size: 'small' } }}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Button
              variant="outlined"
              startIcon={<DownloadIcon />}
              onClick={exportCSV}
              className="mr-1"
            >Export CSV</Button>
            <LoadingButton
              variant="outlined"
              startIcon={<DownloadIcon />}
              loading={isDownloading}
              onClick={downloadRawData}>Export Raw Data</LoadingButton>
          </Grid>
        </Grid>
        {!isLoading &&
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={400}>Event</TableCell>
                <TableCell>Event Time</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === 'session'}
                    direction={'desc'}
                    onClick={() => sortReports('session')}
                  >
                    Session
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === 'user'}
                    direction={'desc'}
                    onClick={() => sortReports('user')}
                  >
                    User
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === 'duration'}
                    direction={'desc'}
                    onClick={() => sortReports('duration')}
                  >
                    Total Duration (hours)
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports.map((report, index) =>
                <TableRow key={index}>
                  <TableCell>{report.Event}</TableCell>
                  <TableCell>{report.Time}</TableCell>
                  <TableCell>{report.Session}</TableCell>
                  <TableCell>{report.User}</TableCell>
                  <TableCell>{report['Total Duration (hours)']}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        }
        {isLoading &&
          <Box mt={4} textAlign="center">
            <CircularProgress />
          </Box>
        }
      </Box>
    </Paper>
  );
}