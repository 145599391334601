import {
  Edit as EditIcon,
  DeleteForever as DeleteForeverIcon,
  ContentCopy as ContentCopyIcon
} from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import axios from 'axios';
import { SERVER_URL } from 'constants/serverUrl';
import moment from 'moment';
import { DataGridListPage } from 'pages/Common';
import { DataGridActionMenu } from 'pages/Common/DataGridActionMenu';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteDataGridRow } from 'store/actions';
import { pageKeys } from 'store/reducers/dataGrid.reducer';
import { getAuthHeader } from 'utils';
import { getEventName } from 'utils/event';
import { StreamAddEdit } from './components/StreamAddEdit';


export const EventStreams = () => {
  const gamesById = useSelector(state => state.games.gamesById);
  const [ eventDetail, setEventDetail ] = useState({});
  const [ stream, setStream ] = useState();
  const [ duplicate, setDuplciate ] = useState(false);
  const [ dialogOpen, setDialogOpen ] = useState(false);
  const { eventId } = useParams();
  const dispatch = useDispatch();

  const onDelete = useCallback((streamId) => {
    const confirmed = window.confirm("Are you sure you want to remove this permanently?");
    if (confirmed) {
      dispatch(deleteDataGridRow(`stream`, streamId, {}, {}, pageKeys.eventStreams));
    }
  }, [dispatch]);

  const onDuplicate = useCallback((data) => {
    setStream(data);
    setDuplciate(true);
    setDialogOpen(true);
  }, []);

  const onEdit = useCallback((stream) => {
    setStream(stream);
    setDialogOpen(true);
  }, []);

  const onAdd = () => {
    setStream(null);
    setDialogOpen(true);
  }

  const onClose = () => {
    setDuplciate(false);
    setDialogOpen(false);
  }

  useEffect(() => {
    axios.get(`${SERVER_URL}/event/${eventId}`, {
      headers: getAuthHeader()
    }).then(res => {
      setEventDetail(res.data);
    }).catch(err => {
      setEventDetail({});
      toast.error(err.message);
    })
  }, [eventId]);

  const columns = useMemo(() => {
    return [
      {
        field: 'rank',
        headerName: 'Rank',
        width: 80,
      },
      {
        field: 'label',
        headerName: 'Label',
        width: 150,
      },
      {
        field: 'level',
        headerName: 'Level',
        width: 150,
      },
      {
        field: 'name',
        headerName: 'Name',
        width: 150,
      },
      {
        field: 'token',
        headerName: 'Token',
        width: 150,
      },
      {
        field: 'delay',
        headerName: 'Delay',
        width: 150,
      },
      {
        field: 'updatedAt',
        headerName: 'Updated At',
        valueFormatter: ({ value }) => value && moment(value).format('Y-MM-DD'),
        width: 120,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        cellStyle: {textAlign: 'center'},
        headerClass: 'text-center',
        cellRenderer: ({ data }) => (
          <DataGridActionMenu>
            <MenuItem onClick={() => onEdit(data)}><EditIcon/>Edit</MenuItem>
            <MenuItem onClick={() => onDuplicate(data)}><ContentCopyIcon/>Duplicate</MenuItem>
            <MenuItem onClick={() => onDelete(data.id)}><DeleteForeverIcon/>Delete</MenuItem>
          </DataGridActionMenu>
        ),
      },
    ];
  }, [onDelete, onDuplicate, onEdit]);

  return (
    <>
      <DataGridListPage
        columns={columns}
        apiEndpoint="stream"
        hideSearchInput
        heading={`Streams - ${getEventName(eventDetail, gamesById)}`}
        pageKey={pageKeys.eventStreams}
        onAdd={onAdd}
        rowsPerPage={50}
        initialQueryParams={{
          eventId
        }}
      />
      <StreamAddEdit
        stream={stream}
        eventId={eventId}
        duplicate={duplicate}
        open={dialogOpen}
        onClose={onClose}
      />
    </>
  )
}

