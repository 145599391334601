import {
  Box,
  CircularProgress,
  Paper,
} from "@mui/material";
import { DynamicFormInputs, useDetailPage, DetailHeading } from "pages/Common";
import React, { useMemo } from "react";

export const CountryDetail = () => {
  const {
    data,
    loading,
    saving,
    onSave,
    onUpdateData,
  } = useDetailPage({
    apiEndpoint: 'country',
  });

  const mainInputs = useMemo(() => {
    if (!data) {
      return [];
    }
    return [
      {
        field: 'name',
        label: 'Name',
        type: 'text',
        value: data.name
      },
      {
        field: 'cio',
        label: 'Cio',
        type: 'text',
        value: data.cio
      },
      {
        field: 'iso3',
        label: 'Iso3',
        type: 'text',
        value: data.iso3
      },
      {
        field: 'iso2',
        label: 'Iso2',
        type: 'text',
        value: data.iso2
      },
      {
        field: 'slug',
        label: 'Slug',
        type: 'text',
        value: data.slug
      },
    ]
  }, [data]);

  return (
    <Paper>
      <Box p={2}>
        {loading &&
          <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        {!!data &&
          <>
            <DetailHeading
              listLink="/country"
              listName="Countries"
              title={data.name}
              saving={saving}
              onSave={onSave}
            />
            <Paper sx={{ padding: 2 }}>
              <DynamicFormInputs inputs={mainInputs} onChange={(value) => onUpdateData(value)} />
            </Paper>
          </>
        }
      </Box>
    </Paper>
  )
};
