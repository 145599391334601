import { applyMiddleware, createStore, compose } from "redux";
import {thunk} from "redux-thunk";
import rootReducer from "./store/reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = (port) => {
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
  return { store };
};
export const { store } = configureStore();
