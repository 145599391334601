import { lighten } from '@mui/material/styles';

const DataTableToolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
  searchWrapper: {
    boxSizing: " border-box",
    width: 300,
    marginLeft: 10,
  },
  // kulimFont: {
  //   fontFamily: "'Kulim Park', serif",
  // },
  searchForm: {
    display: "flex",
    alignItems: "center",
    background: "transparent",
    border: "1px solid #808080",
    marginRight: 0,
    borderRadius: 40,
  },
  searchInput: {
    flex: 1,
    paddingLeft: 5,
    marginLeft: 5,
    cursor: "text",
    border: "none",
    background: "transparent",
    color: "#808080",
    outline: "0",
    fontSize: 14,
    "&::placeholder": {
      color: "#808080",
    },
    "&:-ms-input-placeholder": {
      color: "#808080",
    },
    "&::-ms-input-placeholder": {
      color: "#808080",
    },
  },
  searchIcon: {
    padding: 5,
    color: "#808080",
  },
});

export default DataTableToolbarStyles;
