import {
  Box, CircularProgress, Grid, Paper
} from "@mui/material";
import { DetailHeading, DynamicFormInputs, useDetailPage } from "pages/Common";
import React, { useMemo } from "react";

export const PremiumOrderDetail = () => {
  const {
    id,
    data,
    loading,
    onUpdateData,
    onSave,
    saving,
  } = useDetailPage({
    apiEndpoint: 'order',
    excludedFields: ['event', 'createdAt', 'updatedAt', 'sourceIpAddress', 'orderType']
  });

  const mainInputs = useMemo(() => {
    if (!data) {
      return [];
    }
    return [
      {
        field: 'userId',
        label: 'User',
        type: 'user',
        value: data.userId,
        disabled: id !== 'new',
      },
      {
        field: 'orderRef',
        label: 'Order Ref',
        type: 'text',
        value: data.orderRef,
        disabled: id !== 'new'
      },
      {
        field: 'startTime',
        label: 'Start Time',
        type: 'datetime',
        value: data.startTime
      },
      {
        field: 'endTime',
        label: 'End Time',
        type: 'datetime',
        value: data.endTime
      },
      {
        field: 'status',
        label: 'Status',
        type: 'select',
        options: [
          {id: 'valid', label: 'Valid'},
          {id: 'invalid', label: 'Invalid'},
        ],
        value: data.status || 'valid'
      },
      {
        field: 'notification',
        label: 'Notification',
        type: 'text',
        value: data.notification
      },
    ]
  }, [data, id]);

  return (
    <Paper>
      <Box p={2}>
        {loading &&
          <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        {!!data &&
          <>
            <DetailHeading
              listLink="/premium-order"
              listName="Premium Orders"
              title={id === 'new' ? "New Order" : data.orderRef}
              saving={saving}
              onSave={onSave}
            />
            <Grid container mt={1} spacing={2}>
              <Grid item>
                <Paper sx={{ padding: 2 }}>
                  <DynamicFormInputs inputs={mainInputs} onChange={(value) => onUpdateData(value)} />
                </Paper>
              </Grid>
            </Grid>
          </>
        }
      </Box>
    </Paper>
  )
};
