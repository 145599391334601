import {
  Box,
  CircularProgress,
  Grid,
  Paper,
} from "@mui/material";
import { MediaUpload } from "components";
import { DynamicFormInputs, useDetailPage, DetailHeading } from "pages/Common";
import React, { useMemo } from "react";

export const SponsorDetail = () => {
  const {
    id,
    data,
    loading,
    saving,
    onSave,
    onUpdateData,
  } = useDetailPage({
    apiEndpoint: 'sponsor',
    defaultData: {
      sportId: 1
    }
  });

  const mainInputs = useMemo(() => {
    if (!data) {
      return [];
    }
    return [
      {
        field: 'sportId',
        label: 'Sport',
        type: 'sport',
        value: data.sportId
      },
      {
        field: 'label',
        label: 'Label',
        type: 'text',
        value: data.label
      },
    ]
  }, [data]);

  return (
    <Paper>
      <Box p={2}>
        {loading &&
          <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        {!!data &&
          <>
            <DetailHeading
              listLink="/sponsor"
              listName="Sponsors"
              title={id === 'new' ? "New Sponsor" : data.label}
              saving={saving}
              onSave={onSave}
            />
            <Paper>
              <Box p={2}>
                <Grid container spacing={2}>
                  <Grid item sx={12} md={7}>
                    <DynamicFormInputs inputs={mainInputs} onChange={(value) => onUpdateData(value)} />
                  </Grid>
                  <Grid item sx={12} md={5}>
                    <MediaUpload
                      component="img"
                      label="logo"
                      path={`uploads/sponsors`}
                      fileName={data.logo}
                      onUploaded={(logo) => onUpdateData({ logo })}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </>
        }
      </Box>
    </Paper>
  )
};
