import React from "react";

import FroalaEditor from "react-froala-wysiwyg";

// import "froala-editor/js/languages/en_gb";
// import "froala-editor/js/languages/fr";
// import "froala-editor/js/languages/de";
// import "froala-editor/js/languages/nl";
// import "froala-editor/js/languages/es";
// import "froala-editor/js/languages/ko";
// import "froala-editor/js/languages/vi";
// import "froala-editor/js/languages/tr";

import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/third_party/image_tui.min.js";
import "froala-editor/js/third_party/embedly.min.js";

import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";
import "froala-editor/css/third_party/image_tui.min.css";

import { SERVER_URL, UPLOADED_MEDIA_URL } from "constants/serverUrl";
import { getAuthHeader } from "utils";

const toolbarButtons = {
  moreText: {
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikeThrough",
      "subscript",
      "superscript",
      "fontFamily",
      "fontSize",
      "textColor",
      "backgroundColor",
      "inlineClass",
      "inlineStyle",
      "clearFormatting",
    ],
  },
  moreParagraph: {
    buttons: [
      "alignLeft",
      "alignCenter",
      "formatOLSimple",
      "alignRight",
      "alignJustify",
      "formatOL",
      "formatUL",
      "paragraphFormat",
      "paragraphStyle",
      "lineHeight",
      "outdent",
      "indent",
      "quote",
    ],
  },
  moreRich: {
    buttons: [
      "insertLink",
      "insertImage",
      "insertTable",
      "emoticons",
      "fontAwesome",
      "specialCharacters",
      "embedly",
    ],
  },
  moreMisc: {
    buttons: ["undo", "redo", "print", "getPDF", "selectAll", "help"],
    align: "right",
    buttonsVisible: 2,
  },
};

// Render Froala Editor component.
class EditorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    setTimeout(() => {
      this.parseText();
    }, 200);
  }

  parseText = () => {
    const images = document.querySelectorAll(".fr-element.fr-view img");
    images.forEach((img) => {
      try {
        const src = img.getAttribute("src");
        if (/(http(s?)):\/\//i.test(src)) return;
        img.src = `${UPLOADED_MEDIA_URL}${src}`;
      } catch (_) {
        console.warn(_)
      }
    });
  };

  componentDidUpdate = () => {
    this.parseText();
  };

  render() {
    const {
      text,
      lang,
      placeholder,
    } = this.props;

    return (
      <div>
        <FroalaEditor
          model={text}
          onModelChange={(model) => this.props.onChange(model)}
          config={{
            apiKey:
              "wFE7nE5H5F4E3C7C6B4fLUQZf1ASFb1EFRNh1Hb1BCCQDUHnA8B6E5E5B1C3G3A1C8C6==",
            key:
              "wFE7nE5H5F4E3C7C6B4fLUQZf1ASFb1EFRNh1Hb1BCCQDUHnA8B6E5E5B1C3G3A1C8C6==",
            toolbarSticky: false,
            placeholderText: placeholder ? placeholder : "Edit News Here...",
            language: lang ? lang : "en_us",
            // toolbarInline: true,
            toolbarButtons,
            imageUploadURL: SERVER_URL + "/file",
            quickInsertButtons: [
              "image",
              "embedly",
              "table",
              "ul",
              "ol",
              "emoticons",
              "hr",
            ],
            imageInsertButtons: ["imageBack", "|", "imageUpload", "imageByURL"],
            imageUploadRemoteUrls: true,
            requestHeaders: getAuthHeader(),
            events: {
              'image.uploaded': function (response) {
                try {
                  this.image.insert(JSON.parse(response).url, false, null, this.image.get(), response);
                } catch (error) {
                  console.error(error);
                }
                return false;
              }
            },
          }}
        />
      </div>
    );
  }
}

export default EditorComponent;
