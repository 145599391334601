import { RemoteSourceAutoComplete } from 'components/RemoteSourceAutoComplete';
import React from 'react';
export const CountrySelect = ({ onChange, countryId, disabled = false, placeholder, params = {} }) => {
  return (
    <RemoteSourceAutoComplete
      apiPath="country"
      queryParams={params}
      localFilter={true}
      selectedId={countryId}
      disabled={disabled}
      getOptionLabel={(option => option.name)}
      onChange={onChange}
      placeholder={placeholder || "Country"}
    />
  )
};
