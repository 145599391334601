import {
  ACTION_GET_ADMINISTRATORS_START,
  ACTION_GET_ADMINISTRATORS_FINISH,
  ACTION_GET_ADMINISTRATORS_ERROR,
  ACTION_SAVE_ADMINISTRATOR_START,
  ACTION_SAVE_ADMINISTRATOR_SUCCESS,
  ACTION_SAVE_ADMINISTRATOR_ERROR,
} from "store/actionTypes/actionAdministrators";
const initialState = {
  loading: false,
  data: [],
  err: null,
  uploadError: -1,
  uploadErrorDetail: null,
};

export default function (state = initialState, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case ACTION_GET_ADMINISTRATORS_START:
      newState.loading = true;
      newState.err = null;
      return newState;
    case ACTION_GET_ADMINISTRATORS_FINISH:
      newState.loading = false;
      if (Array.isArray(action.payload)) newState.data = action.payload;
      newState.err = null;
      return newState;
    case ACTION_GET_ADMINISTRATORS_ERROR:
      newState.loading = false;
      newState.err = action.payload;
      return newState;

    case ACTION_SAVE_ADMINISTRATOR_START:
      newState.uploading = true;
      newState.uploadError = -1;
      newState.uploadErrorDetail = null;
      return newState;

    case ACTION_SAVE_ADMINISTRATOR_SUCCESS:
      console.log("update admin successed");
      newState.uploading = false;
      newState.uploadError = 0;
      newState.uploadErrorDetail = null;
      return newState;
    case ACTION_SAVE_ADMINISTRATOR_ERROR:
      newState.uploading = false;
      newState.uploadError = 1;
      newState.uploadErrorDetail = action.payload;
      return newState;
    default:
      return newState;
  }
}
