const HeaderStyles = (theme) => ({
  appBar: {
    // boxShadow: '0 1px 8px rgba(0,0,0,.3)',
    position: "relative",
    zIndex: theme.zIndex.drawer + 100,
    [theme.breakpoints.down('md')]: {
      position: "fixed",
    },
  },
  toolBar: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },

  primaryNavsContainer: {
    display: "flex",
    maxWidth: 850,
    height: "inherit",
    justifyContent: "space-between",
    flex: 1.5,
  },
  branding: {
    display: "flex",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    margin: "auto 0",
    lineHeight: "50px",
    padding: `0 64px 0 0`,
  },
  logo: {
    margin: "auto",
    [theme.breakpoints.down('md')]: {
      maxWidth: "80px",
    },
  },
  searchWrapper: {
    flex: "1 1 0%",
    boxSizing: " border-box",
  },
  searchForm: {
    background: "transparent",
    position: "relative",
    // borderRadius: theme.shape.borderRadius,
    border: "1px solid gray",
    marginRight: theme.spacing(2),
    display: "block",
    maxWidth: "800px",
  },
  searchInput: {
    fontSize: "1rem",
    padding: theme.spacing(1.2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.2),
    },
    cursor: "text",
    border: "none",
    background: "transparent",
    color: "white",
    width: "100%",
    outline: "0",
  },
  searchIcon: {
    position: "absolute",
    top: "50%",
    right: "0",
    marginTop: "-24px",
    color: "white",
  },
});

export default HeaderStyles;
