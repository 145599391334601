import { authorizationHeader } from "auth";
import axios from "axios";
import { SERVER_URL } from "constants/serverUrl";
import { GET_CURRENT_USER, GET_CURRENT_USER_FAILURE, GET_CURRENT_USER_SUCCESS } from "store/actionTypes/auth";

const url = `${SERVER_URL}/user`;

export const getCurrentUser = () => {
  return (dispatch) => {
    dispatch({
      type: GET_CURRENT_USER,
    });
    axios.get(`${url}/me`, authorizationHeader())
      .then((res) => {
        dispatch({
          type: GET_CURRENT_USER_SUCCESS,
          payload: {
            userInfo: res.data,
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_CURRENT_USER_FAILURE,
          payload: {
            error: err
          }
        });
      });
  };
};