import { RemoteSourceAutoComplete } from 'components/RemoteSourceAutoComplete';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { getBookmarkedEvents } from 'utils/event';
import { getGameName } from 'utils';
import { useSelector } from 'react-redux';

export const EventSelect = ({ onChange, eventId, disabled = false, placeholder, params = {} }) => {
  const [bookMarkedEvents, setBookmarkedEvents] = useState([]);
  const gamesById = useSelector(state => state.games.gamesById);
  useEffect(() => {
    setBookmarkedEvents(getBookmarkedEvents());
  }, []);

  const getOptionLabel = useCallback((option) => {
    return `${option.id}, ${getGameName(gamesById, option.gameId)}, ${option.eventTypeName}, ${option.city}, ${moment(option.startTime).format("L")} - ${moment(option.endTime).format("L")} ${option.isBookmarked ? '⭐' : ''}`
  }, [gamesById]);

  return (
    <RemoteSourceAutoComplete
      apiPath="event"
      queryParams={{
        ...params,
        isAdmin: 1,
        orderBy: "startTime",
        order: "DESC",
        limit: 50
      }}
      searchKey="searchStr"
      selectedId={eventId}
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      placeholder={placeholder || "Event"}
      staticList={bookMarkedEvents}
    />
  )
};
