import {
  GET_SPORTS_START,
  GET_SPORTS_FINISH,
  GET_SPORTS_ERROR,
} from "../actionTypes/sport";
const initialState = {
  loading: false,
  data: [],
  sportsById: {},
  err: null,
};

export default function (state = initialState, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case GET_SPORTS_START:
      newState.loading = true;
      newState.err = null;
      return newState;
    case GET_SPORTS_FINISH:
      newState.loading = false;
      if (Array.isArray(action.payload)) {
        newState.data = action.payload;
        action.payload.forEach(item => {
          newState.sportsById[item.id] = item;
        });
      }
      newState.err = null;
      return newState;
    case GET_SPORTS_ERROR:
      newState.loading = false;
      newState.err = action.payload;
      return newState;
    default:
      return newState;
  }
}
