import { RemoteSourceAutoComplete } from 'components/RemoteSourceAutoComplete';
import React from 'react';

export const UserSelect = ({ onChange, userId, disabled = false, placeholder, params = {} }) => {
  return (
    <RemoteSourceAutoComplete
      apiPath='user'
      queryParams={{
        ...params,
        limit: 50
      }}
      selectedId={userId}
      disabled={disabled}
      getOptionLabel={(option => `${option.firstName} ${option.lastName} (${option.email})`)}
      onChange={onChange}
      placeholder={placeholder || "Select User"}
    />
  )
};
