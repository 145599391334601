import { NavigateNext } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box, Breadcrumbs,
  CircularProgress,
  Grid, Paper,
  Typography,
  Button
} from "@mui/material";
import { MediaUpload } from "components/MediaUpload";
import { TranslationEditor } from "components/TranslationEditor";
import { DynamicFormInputs, useDetailPage } from "pages/Common";
import React, { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { NewsPreview } from "./Preview";

export const NewsDetail = () => {
  const {
    id,
    data: news,
    loading,
    saving,
    onSave,
    onUpdateData,
  } = useDetailPage({
    apiEndpoint: 'news',
    additionalQueryParams: {isAdmin: 1}
  });

  const [previewOpen, setPreviewOpen] = useState(false);
  const [lang, setLang] = useState('en');

  const mainInputs = useMemo(() => {
    if (!news) {
      return [];
    }
    return [
      {
        field: 'gameId',
        label: 'Game',
        type: 'game',
        value: news.gameId
      },
      {
        field: 'eventId',
        label: 'Event',
        type: 'event',
        value: news.eventId
      },
      {
        field: 'isHighlighted',
        label: 'Social Widget',
        type: 'switch',
        value: news.isHighlighted
      },
      
    ]
  }, [news]);

  return (
    <Paper>
      <Box p={2}>
        {loading &&
          <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        {!!news &&
          <>
            <Box display="flex">
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNext fontSize="small" />}
                sx={{ marginRight: 'auto' }}
              >
                <NavLink to={"/article"}>
                  <Typography variant="h6" color="textSecondary">Articles</Typography>
                </NavLink>
                <Typography color="textPrimary" variant="h6">
                  {id === 'new' ? "New Article" : news.title}
                </Typography>
              </Breadcrumbs>
              <Button
                color="inherit"
                variant="outlined"
                onClick={() => setPreviewOpen(true)}
                sx={{marginRight: 2}}
                >Preview</Button>
              <NewsPreview
                translation={news.i18n ? news.i18n.find(item => item.lang === lang) : {}}
                open={previewOpen}
                thumbnail={news.thumbnail}
                onClose={() => setPreviewOpen(false)}
              />
              <LoadingButton
                variant="contained"
                color="primary"
                loading={saving}
                onClick={onSave}
              >
                Save
              </LoadingButton>
            </Box>
            <Grid container mt={1} spacing={2}>
              <Grid item xs={12} sm={12} md={7}>
                <Paper sx={{ padding: 2 }}>
                  <DynamicFormInputs inputs={mainInputs} fullWidth={true} onChange={(value) => onUpdateData(value)} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <MediaUpload
                  component="img"
                  label="Thumbnail"
                  fileUrl={news.thumbnail}
                  onUploaded={(thumbnail) => onUpdateData({ thumbnail })}
                />
              </Grid>
            </Grid>
            <Paper sx={{ padding: 2, marginTop: 2 }}>
              <TranslationEditor
                i18n={news.i18n}
                inputs={[
                  {field: 'isOnline', type: 'switch', label: 'Is Online', fullWidth: false},
                  {field: 'credit', type: 'text', label: 'Credit', fullWidth: false},
                  {field: 'postedByEmail', type: 'text', label: 'Publisher Email', fullWidth: false},
                  {field: 'publishedDate', type: 'datetime', label: 'Published Date', fullWidth: false},
                  {field: 'title', type: 'text', label: 'Title'},
                  {field: 'legend', type: 'text', label: 'Legend'},
                  {field: 'text', type: 'richtext', label: 'Content'},
                ]}
                onUpdate={(i18n) => onUpdateData({i18n})}
                langChange={setLang}
              />
            </Paper>
          </>
        }
      </Box>
    </Paper>
  )
};
