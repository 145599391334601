import {
  Edit as EditIcon
} from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { DataGridActionMenu, DataGridListPage } from 'pages/Common';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { pageKeys } from 'store/reducers/dataGrid.reducer';
import { getSportName } from 'utils';


export const EventTypeList = () => {
  const history = useHistory();
  const sportsById = useSelector(state=> state.sports.sportsById);

  const columns = useMemo(() => {

    return [
      {
        field: 'id',
        headerName: 'Id',
        width: 80,
      },
      {
        field: 'sportId',
        headerName: 'Sport',
        width: 120,
        valueFormatter: ({ value }) => getSportName(sportsById, value),
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        cellStyle: {textAlign: 'center'},
        headerClass: 'text-center',
        cellRenderer: ({ data }) => (
          <>
            <DataGridActionMenu>
              <MenuItem onClick={() => history.push(`/eventType/${data.id}`)}><EditIcon />Edit</MenuItem>
            </DataGridActionMenu>
          </>
        ),
      },
    ]
  }, [history, sportsById]);

  return <DataGridListPage
    columns={columns}
    filters={[]}
    apiEndpoint="eventType"
    initialQueryParams={{
      lang: 'en',
      limit: 20,
    }}
    hideSearchInput
    pageKey={pageKeys.eventTypeList}
  />
}

