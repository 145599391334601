import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import { IconButton, Tooltip, Typography, Box, Button } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import { FilterPopover } from "./FilterPopover";
import { DynamicFormInputs } from ".";

const useStyles = makeStyles((theme) => ({
  searchWrapper: {
    boxSizing: " border-box",
    width: 300,
    marginLeft: 10,
  },
  searchForm: {
    display: "flex",
    alignItems: "center",
    background: "transparent",
    border: "1px solid #808080",
    marginRight: 0,
    borderRadius: 40,
  },
  searchInput: {
    flex: 1,
    paddingLeft: 5,
    marginLeft: 5,
    cursor: "text",
    border: "none",
    background: "transparent",
    color: "#808080",
    outline: "0",
    fontSize: 14,
    "&::placeholder": {
      color: "#808080",
    },
    "&:-ms-input-placeholder": {
      color: "#808080",
    },
    "&::-ms-input-placeholder": {
      color: "#808080",
    },
  },
  searchIcon: {
    padding: 5,
    color: "#808080",
  },
}));

export const DataTableToolbar = ({
  heading,
  hideSearchInput,
  showSaveRanking,
  onAdd,
  searchKey,
  searchPlaceholder,
  onSaveRankings,
  filters,
  onFiltersChange,
}) => {
  const classes = useStyles();
  const [searchStr, setSearchStr] = useState('');
  const [filterInputs, setFilterInputs] = useState(filters);
  const filtersChange = useCallback((filterData) => {
    onFiltersChange({
      ...filterData,
      [searchKey]: searchStr.trim()
    });
    setFilterInputs(filterInputs.map(input => ({
      ...input,
      value: filterData[input.field]
    })));
  }, [filterInputs, searchKey, searchStr, onFiltersChange])

  const onSearch = useCallback(
    () => onFiltersChange({[searchKey]: searchStr.trim()}),
    [searchKey, searchStr, onFiltersChange]
  );

  return (
    <Box display="flex" py={2}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h6">
          {heading}
        </Typography>
      </Box>
      {showSaveRanking &&
      <Box>
        <Button variant="contained" onClick={onSaveRankings}>Save</Button>
      </Box>
      }
      <Box display="flex" alignItems="center">
        {!hideSearchInput &&
          <div className={classes.searchWrapper}>
            <form className={classes.searchForm} onSubmit={(e) => {
              e.preventDefault();
              onSearch();
            }}>
              <input
                className={classes.searchInput}
                type="text"
                placeholder={searchPlaceholder}
                value={searchStr}
                onChange={(e) => setSearchStr(e.target.value)}
              />
              <IconButton
                aria-label="Search"
                className={classes.searchIcon}
                onClick={onSearch}
                size="large">
                <SearchIcon />
              </IconButton>
            </form>
          </div>
        }
        {typeof onAdd === 'function' &&
          <Box ml={1}>
            <Tooltip title="Add New Item">
              <IconButton aria-label="Add New Item" onClick={onAdd}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Box>
        }
        {Boolean(filterInputs && filterInputs.length >= 2) &&
          <FilterPopover filters={filterInputs} onFiltersChange={filtersChange} />
        }
        {Boolean(filterInputs && filterInputs.length === 1) &&
          <Box display="flex" alignItems="center" minWidth={150} ml={2}>
            <DynamicFormInputs inputs={filterInputs} onChange={filtersChange} showReset={false} vertical={false} fullWidth={true} />
          </Box>
        }
      </Box>
    </Box>
  );
}

DataTableToolbar.propTypes = {
  heading: PropTypes.string.isRequired,
  onSearch: PropTypes.func,
  searchKey: PropTypes.string,
  FilterComponent: PropTypes.object,
  hideAddIcon: PropTypes.bool,
};

