import React from "react";
import PropTypes from "prop-types";

import { Card, CardContent, Typography, IconButton, Grid } from "@mui/material";

import withStyles from '@mui/styles/withStyles';

import StatCardStyles from "styles/Components/Cards/StatCard";

const StatCard = (props) => {
  const { classes, type, title, value, icon, color } = props;

  let before = null;
  let after = null;

  const cardIcon = (
    <Grid item className={type === "fill" ? classes.iconFloat : null}>
      <IconButton
        className={classes.icon}
        aria-label={title}
        style={{ backgroundColor: color }}
        size="large">
        {icon}
      </IconButton>
    </Grid>
  );

  if (icon) {
    type === "fill" ? (after = cardIcon) : (before = cardIcon);
  }

  return (
    <Card style={type === "fill" ? { backgroundColor: color || '#e51135' } : null}>
      <CardContent className={classes.content}>
        <Grid
          container
          alignItems={"center"}
          direction={"row"}
          justifyContent={"flex-start"}
        >
          {before}
          <Grid item>
            <div className={type === "fill" ? "pr-1" : "px-1"}>
              <Typography
                variant="h6"
                className={type === "fill" ? classes.lightText : null}
              >
                {typeof value === 'number' ? value.toLocaleString() : value}
              </Typography>
              <Typography
                variant="body1"
                className={type === "fill" ? classes.lightText : null}
              >
                {title}
              </Typography>
            </div>
          </Grid>
          {after}
        </Grid>
      </CardContent>
    </Card>
  );
};

StatCard.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.oneOf(["fill"]),
  title: PropTypes.string,
  value: PropTypes.number,
  icon: PropTypes.element,
  color: PropTypes.string,
};

export default withStyles(StatCardStyles)(StatCard);
