import React from "react";
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { RemoteSourceAutoComplete } from "components";
import { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import { DynamicFormInputs } from "pages/Common";


const ListSection = ({ sectionTitle, modalTitle, modalEditTitle, noItemsMessage, getItemRouterLink, getPrimaryLabel, getSecondaryLabel, onUpdate, items, apiPath, selectComponent, dynamicInputs }) => {
  const [modalShow, setModalShow] = useState(false);
  const [editInputs, setEditInputs] = useState(null);
  const [editingIndex, setEdittingIndex] = useState(-1);
  const [item, setItem] = useState(null);
  const onClose = () => {
    setModalShow(false);
  }
  const onAdded = useCallback(() => {
    onUpdate([...items, item]);
    setModalShow(false);
  }, [items, item, onUpdate]);

  const onDelete = (id) => {
    onUpdate(items.filter(i => i.id !== id));
  }
  const onAdd = () => {
    setEditInputs(null);
    setModalShow(true);
  }
  const onEdit = (item, index) => {
    setEdittingIndex(index);
    setEditInputs([
      {field: 'id', type: 'hidden', value: item.id},
      ...dynamicInputs.map(i => ({
        ...i,
        value: item[i.field]
      }))
    ]);
    setModalShow(true);
  }

  const onEdited = useCallback(() => {
    onUpdate(items.map((value, index) => index === editingIndex ? {...item} : value));
    setModalShow(false);
  }, [items, item, onUpdate, editingIndex]);

  const Select = selectComponent;
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6">{sectionTitle}</Typography>
        <IconButton edge="end" aria-label="add" onClick={onAdd}>
          <AddIcon />
        </IconButton>
      </Box>
      {!items.length &&
        <Box mt={2}>
          <Alert severity="warning">{noItemsMessage || 'No items'}</Alert>
        </Box>
      }
      {!!items.length &&
        <List dense>
          {items.map((i, key) => (
            <ListItem key={key} disableGutters>
              {(() => {
                const link = getItemRouterLink(i);
                if (link?.startsWith('http')) {
                  return (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                      <ListItemText
                        primary={getPrimaryLabel(i)}
                        secondary={getSecondaryLabel ? getSecondaryLabel(i) : undefined}
                      />
                    </a>
                  );
                } else {
                  return (
                    <NavLink to={link || ''} target="_blank">
                      <ListItemText
                        primary={getPrimaryLabel(i)}
                        secondary={getSecondaryLabel ? getSecondaryLabel(i) : undefined}
                      />
                    </NavLink>
                  )
                }
              })()}
              <ListItemSecondaryAction>
                {!!dynamicInputs &&
                  <IconButton onClick={() => onEdit(i, key)} edge="end">
                    <EditIcon />
                  </IconButton>
                }
                <IconButton onClick={() => onDelete(i.id)} edge="end">
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      }
      <Dialog onClose={onClose} aria-labelledby="dialog-title" maxWidth="sm" fullWidth open={modalShow}>
        <DialogTitle>{!editInputs ? modalTitle : modalEditTitle}</DialogTitle>
        <DialogContent>
          <Box pt={2}>
            {!!Select &&
              <Select
                onChange={(item) => setItem(item)}
              />
            }
            {!!dynamicInputs &&
              <DynamicFormInputs inputs={editInputs ? editInputs : dynamicInputs} onChange={(value) => setItem(value)} fullWidth={true} />
            }
            {!!apiPath && !Select && !dynamicInputs &&
              <RemoteSourceAutoComplete
                apiPath={apiPath}
                queryParams={{
                  limit: 50
                }}
                getOptionLabel={getPrimaryLabel}
                onChange={(item) => setItem(item)}
              />
            }
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={editInputs ? onEdited : onAdded} variant="contained" color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ListSection;