import {
  Edit as EditIcon,
  DeleteForever as DeleteForeverIcon,
} from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { DataGridActionMenu, DataGridListPage } from 'pages/Common';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteDataGridRow } from 'store/actions';
import { pageKeys } from 'store/reducers/dataGrid.reducer';


export const GameList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const sportsById = useSelector(state => state.sports.sportsById);

  const onDelete = useCallback((gameId) => {
    const confirmed = window.confirm("Are you sure you want to delete this game permanently?");
    if (confirmed) {
      dispatch(deleteDataGridRow(`game`, gameId, {}, {}, pageKeys.gameList));
    }
  }, [dispatch]);
  
  const columns = useMemo(() => {

    return [
      {
        field: 'id',
        headerName: 'Id',
        width: 80,
      },
      {
        field: 'name',
        headerName: 'Name',
        width: 300,
      },
      {
        field: 'sportId',
        headerName: 'Sport',
        valueFormatter: ({value}) => sportsById[value] ? sportsById[value].name : '',
        width: 150,
      },
      {
        field: 'icon',
        headerName: 'Icon',
        width: 150,
        cellRenderer: ({ value }) => <img src={value} alt="Icon" width="30"/>
      },
      {
        field: 'bannerImage',
        headerName: 'Banner',
        flex: 1,
        cellRenderer: ({ value }) => <img src={value} alt="Banner" width="120"/>
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        cellRenderer: ({ data }) => (
          <>
            <DataGridActionMenu>
              <MenuItem onClick={() => history.push(`/game/${data.id}`)}><EditIcon />Edit</MenuItem>
              <MenuItem onClick={() => onDelete(data.id)}><DeleteForeverIcon/>Remove</MenuItem>
            </DataGridActionMenu>
          </>
        ),
        cellStyle: { textAlign: 'center' },
        headerClass: 'text-center'
      },
    ]
  }, [history, sportsById, onDelete]);

  const filters = useMemo(() => [
      {
        field: 'sportId',
        label: 'Sport',
        type: 'sport',
        value: 1
      },
    ], []);

  return <DataGridListPage
    columns={columns}
    filters={filters}
    apiEndpoint="game"
    heading="Games"
    hideSearchInput
    pageKey={pageKeys.gameList}
    hasPagination={false}
    onAdd={() => history.push(`/game/new`)}
  />
}

