import EditIcon from '@mui/icons-material/Edit';
import PlaylistDeleteForeverIcon from '@mui/icons-material/PlaylistRemove';
import { MenuItem } from '@mui/material';
import axios from 'axios';
import { SERVER_URL } from 'constants/serverUrl';
import moment from 'moment';
import { DataGridListPage } from 'pages/Common';
import { DataGridActionMenu } from 'pages/Common/DataGridActionMenu';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteDataGridRow, saveRowRankings } from 'store/actions';
import { pageKeys } from 'store/reducers/dataGrid.reducer';
import { getAuthHeader, getGameName } from 'utils';
import { getEventName } from 'utils/event';
import { formatSeconds } from 'utils/time';

export const EventPlaylist = () => {
  const gamesById = useSelector(state => state.games.gamesById);
  const [ eventDetail, setEventDetail ] = useState({});
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const onDeleteVideo = useCallback((videoId, eventId) => {
    const confirmed = window.confirm("Are you sure you want to remove this video from the playlist?");
    if (confirmed) {
      dispatch(deleteDataGridRow(`eventVideo`, videoId, { eventId, videoId }, {}, pageKeys.eventPlaylist));
    }
  }, [dispatch]);

  const onSaveRankings = (videoIds) => {
    dispatch(saveRowRankings('eventVideo', {videoIds, eventId}, {}, pageKeys.eventPlaylist));
  }

  useEffect(() => {
    axios.get(`${SERVER_URL}/event/${eventId}`, {
      headers: getAuthHeader()
    }).then(res => {
      setEventDetail(res.data);
    }).catch(err => {
      setEventDetail({});
      toast.error(err.message);
    })
  }, [eventId]);

  const columns = useMemo(() => {
    return [
      {
        field: 'id',
        headerName: 'Id',
        width: 100,
        rowDrag: true,
      },
      {
        field: 'thumbnail',
        headerName: 'Thumbnail',
        cellRenderer: ({ data }) => <img
          src={`https://kozoom.b-cdn.net/uploads/events/${data.eventId}/videos/${data.thumbnail}`}
          alt="thumb"
          width="100%" />,
        width: 150
      },
      {
        field: 'description',
        headerName: 'Description',
        width: 250,
      },
      {
        field: 'gameId',
        headerName: 'Game',
        valueFormatter: ({ value }) => getGameName(gamesById, value),
        width: 120,
      },
      {
        field: 'city',
        headerName: 'City',
        width: 120,
      },
      {
        field: 'duration',
        headerName: 'Duration',
        valueFormatter: (({ value }) => formatSeconds(value)),
        width: 120,
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        valueFormatter: ({ value }) => value && moment(value).format('Y-MM-DD'),
        width: 120,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        cellStyle: {textAlign: 'center'},
        headerClass: 'text-center',
        cellRenderer: ({ data }) => (
          <DataGridActionMenu>
            <MenuItem onClick={() => onDeleteVideo(data.id, Number(eventId))}><PlaylistDeleteForeverIcon/>Remove from playlist</MenuItem>
            <MenuItem onClick={() => history.push(`/video/${data.id}`)}><EditIcon/>Edit</MenuItem>
          </DataGridActionMenu>
        ),
      },
    ];
  }, [gamesById, onDeleteVideo, eventId, history]);

  return (
    <>
      <DataGridListPage
        columns={columns}
        apiEndpoint="video"
        hideSearchInput
        heading={`Playlist - ${getEventName(eventDetail, gamesById)}`}
        pageKey={pageKeys.eventPlaylist}
        onAdd={() => history.push(`/video`)}
        onSaveRankings={onSaveRankings}
        rowsPerPage={200}
        initialQueryParams={{
          eventId
        }}
      />
    </>
  )
}

