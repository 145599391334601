import {
  GET_COUNTRIES_START,
  GET_COUNTRIES_FINISH,
  GET_COUNTRIES_ERROR,
} from "../actionTypes/country";

const initialState = {
  loading: false,
  data: [],
  countriesById: {},
  err: null,
};

export default function (state = initialState, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case GET_COUNTRIES_START:
      newState.loading = true;
      newState.err = null;
      return newState;
    case GET_COUNTRIES_FINISH:
      newState.loading = false;
      if (Array.isArray(action.payload)) {
        const countriesById = {};
        action.payload.forEach(country => {
          countriesById[country.id] = country;
        });
        newState.countriesById = countriesById;
        newState.data = action.payload;
      }
      newState.err = null;
      return newState;
    case GET_COUNTRIES_ERROR:
      newState.loading = false;
      newState.err = action.payload;
      return newState;
    default:
      return newState;
  }
}
