import { getIdToken } from "auth";

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

export const getFlagUrlByIso2 = (iso2) => {
  return `https://b-cdn.kozoom.com/assets/static/images/flags/${iso2}.svg`;
};
/**
 *
 * @param {array} gamesById game data with i18n
 * @param {number} gameId
 * @param {string} lang current language
 *
 * @returns string
 */
export const getGameName = (gamesById, gameId) => {
  return gamesById[gameId] ? gamesById[gameId].name : '';
};

export const getSportName = (sportsById, sportId) => {
  return sportsById[sportId] ? sportsById[sportId].name : '';
};

/**
 * Find Element From Object Array By Id
 * @param {array} data
 * @param {number} id
 * @param {string} key optional id field
 *
 * @returns {Object}
 */
export const findItemById = (data, id, key = "") => {
  let res = null;
  for (var item of data) {
    let idKey = "id";
    if (key !== "") idKey = key;
    if (item[idKey] === id) {
      res = item;
      break;
    }
  }
  return res;
};

export const copyStringToClipboard = (str) => {
  // Create new element
  var el = document.createElement("textarea");
  // Set value (string to be copied)
  el.value = str;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute("readonly", "");
  el.style = {
    position: "absolute",
    left: "-9999px",
  };
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand("copy");
  // Remove temporary element
  document.body.removeChild(el);
};

export const b64toBlob = (dataURI) => {
  var byteString = atob(dataURI.split(",")[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {
    type: "image/png",
  });
};

export function blobToFile(theBlob, fileName) {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
}

export const getAccessToken = () => {
  return `Bearer ${getIdToken()}`;
}

export const getAuthHeader = () => {
  return {
    Authorization: `Bearer ${getIdToken()}`
  }
}
