import {
  GET_GAMES_START,
  GET_GAMES_FINISH,
  GET_GAMES_ERROR,
} from "store/actionTypes/game";
import { getAccessToken } from "utils";
import { SERVER_URL } from "constants/serverUrl";
import axios from "axios";

const url = `${SERVER_URL}/game`;
export const getGames = () => {
  return (dispatch) => {
    console.log("action get games");
    dispatch({
      type: GET_GAMES_START,
    });
    axios
      .get(url, {
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_GAMES_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_GAMES_ERROR,
          payload: err,
        });
      });
  };
};
