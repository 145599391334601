import React from "react";
import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { DataGridActionMenu, DataGridListPage } from 'pages/Common';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteDataGridRow } from 'store/actions';
import { pageKeys } from 'store/reducers/dataGrid.reducer';
import { getSportName } from 'utils';


export const EquipmentList = () => {
  const history = useHistory();
  const sportsById = useSelector(state=> state.sports.sportsById);
  const dispatch = useDispatch();

  const onDelete = useCallback((id) => {
    const confirmed = window.confirm("Are you sure you want to delete this equipment permanently?");
    if (confirmed) {
      dispatch(deleteDataGridRow('equipment', id, {}, {}, pageKeys.equipmentList));
    }
  }, [dispatch]);
  
  const columns = useMemo(() => {

    return [
      {
        field: 'id',
        headerName: 'Id',
        width: 80,
      },
      {
        field: 'sportId',
        headerName: 'Sport',
        width: 100,
        valueFormatter: ({ value }) => getSportName(sportsById, value),
      },
      {
        field: 'marque',
        headerName: 'Marque',
        width: 100,
      },
      {
        field: 'equipmentType',
        headerName: 'Type',
        width: 100,
      },
      {
        field: 'storeUrl',
        headerName: 'Store URL',
        width: 500,
        cellRenderer: ({value}) => <a href={value} target='_blank' rel="noopener noreferrer">{value}</a>
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        cellStyle: {textAlign: 'center'},
        headerClass: 'text-center',
        cellRenderer: ({ data }) => (
          <>
            <DataGridActionMenu>
              <MenuItem onClick={() => history.push(`/equipment/${data.id}`)}><EditIcon />Edit</MenuItem>
              <MenuItem onClick={() => onDelete(data.id)}><DeleteForeverIcon/>Remove</MenuItem>
            </DataGridActionMenu>
          </>
        ),
      },
    ]
  }, [history, sportsById, onDelete]);

  return <DataGridListPage
    columns={columns}
    filters={[]}
    apiEndpoint="equipment"
    heading="Equipments"
    hideSearchInput
    hasPagination={false}
    onAdd={() => history.push('/equipment/new')}
    pageKey={pageKeys.equipmentList}
  />
}

