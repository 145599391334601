import {
  Edit as EditIcon,
  DeleteForever as DeleteForeverIcon
} from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import moment from 'moment';
import { DataGridActionMenu, DataGridListPage } from 'pages/Common';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteDataGridRow } from 'store/actions';
import { pageKeys } from 'store/reducers/dataGrid.reducer';
import { getGameName } from 'utils';


export const NewsList = () => {
  const gamesById = useSelector(state => state.games.gamesById);
  const history = useHistory();
  const dispatch = useDispatch();

  const onDeleteNews = useCallback((newsId) => {
    const confirmed = window.confirm("Are you sure you want to delete this news permanently?");
    if (confirmed) {
      dispatch(deleteDataGridRow(`news`, newsId, {}, {}, pageKeys.newsList));
    }
  }, [dispatch]);
  
  const columns = useMemo(() => {

    return [
      {
        field: 'id',
        headerName: 'Id',
        width: 80,
      },
      {
        field: 'title',
        headerName: 'Title',
        flex: 1,
      },
      {
        field: 'publishedDate',
        headerName: 'Published At',
        valueFormatter: ({ value }) => value && moment(value).format('Y-MM-DD'),
        width: 120,
      },
      {
        field: 'eventTypeName',
        headerName: 'Event Type',
        flex: 1,
      },
      {
        field: 'gameId',
        headerName: 'Game',
        valueFormatter: ({ value }) => getGameName(gamesById, value),
        width: 100,
      },
      {
        field: 'translated',
        headerName: 'Translated',
        valueFormatter: ({ value }) => 
          value.toUpperCase().split(',').join(', ')
        ,
        width: 220,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        cellRenderer: ({ data }) => (
          <>
            <DataGridActionMenu>
              <MenuItem onClick={() => history.push(`/article/${data.id}`)}><EditIcon />Edit</MenuItem>
              <MenuItem onClick={() => onDeleteNews(data.id)}><DeleteForeverIcon/>Remove</MenuItem>
            </DataGridActionMenu>
          </>
        ),
        cellStyle: { textAlign: 'center' },
        headerClass: 'text-center'
      },
    ]
  }, [gamesById, history, onDeleteNews]);

  const filters = useMemo(() => [
      {
        field: 'sportId',
        label: 'Sport',
        type: 'sport',
        value: null
      },
    ], []);

  return <DataGridListPage
    columns={columns}
    filters={filters}
    apiEndpoint="news"
    heading="Articles"
    pageKey={pageKeys.newsList}
    onAdd={() => history.push(`/article/new`)}
    initialQueryParams={{
      isAdmin: 1
    }}
  />
}

