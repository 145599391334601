import React from 'react';
import { NavigateNext } from "@mui/icons-material"
import { Box, Breadcrumbs, Typography } from "@mui/material"
import { NavLink } from "react-router-dom"
import { LoadingButton } from '@mui/lab';

export const DetailHeading = ({ listLink, listName, title, saving, onSave = () => { } }) => {
  return <Box display="flex" mb={2}>
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNext fontSize="small" />}
      sx={{ marginRight: 'auto' }}
    >
      <NavLink to={listLink}>
        <Typography variant="h6" color="textSecondary">{listName}</Typography>
      </NavLink>
      <Typography color="textPrimary" variant="h6">
        { title }
      </Typography>
    </Breadcrumbs>
    <LoadingButton
      variant="contained"
      color="primary"
      loading={saving}
      onClick={onSave}
    >
      Save
    </LoadingButton>
  </Box>
}