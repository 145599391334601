import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box
} from "@mui/material";

import ReactHtmlParser from "react-html-parser";
import { UPLOADED_MEDIA_URL } from "constants/serverUrl";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    "& *": {
      fontFamily: '"Kulim Park", serif !important'
    },
    "& img": {
      display: 'block',
      margin: 'auto'
    },
    "& .fr-img-space-wrap": {
      textAlign: 'center'
    },
    "& .fr-img-wrap": {
      textAlign: 'center',
      display: 'block'
    }
  },
  thumbnail: {
    width: '90%',
    minWidth: 320,
    minHeight: 180,
    border: '1px solid #ddd'
  }
}))

const getNewsContent = (text) => {
  return ReactHtmlParser(text ? text : "", {
    transform: function (node, index) {
      if (node.type === "tag" && node.name === "img") {
        if (
          !node.attribs.src.includes("https://") &&
          !node.attribs.src.includes("http://")
        )
          node.attribs.src = `${UPLOADED_MEDIA_URL}/${node.attribs.src}`;
      }
    },
  });
}

export const NewsPreview = ({translation, open, onClose, thumbnail}) => {
  const classes = useStyles();
  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle>News Preview</DialogTitle>
      <DialogContent>
        <div className={classes.root}>
          <Box mb={2}>
            <Typography variant="h5">
              {translation ? translation.title : ''}
            </Typography>
          </Box>
          {!!thumbnail &&
            <img src={thumbnail} alt="thumbnail" className={classes.thumbnail}/>
          }
          <div>{getNewsContent(translation ? translation.text : '')}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
