// Icons
import {
  AppsOutlined as AppsIcon,
  AssignmentOutlined as AssignmentIcon,
  HomeOutlined as HomeIcon,
  LiveTv as LiveTvIcon,
  SettingsOutlined as SettingsIcon,
  VerifiedUserOutlined as VerifiedUserOutlinedIcon,
} from "@mui/icons-material";

// Pages
import {
  Administrators,
  AudienceReport,
  ConfigurationList,
  CountryDetail,
  CountryList,
  EventDetail,
  EventList,
  EventPlaylist,
  EventSchedule,
  EventStreams,
  EventTypeDetail,
  EventTypeList,
  GameDetail,
  GameList,
  ImportVideos,
  NewsDetail,
  NewsList,
  Other,
  Overview,
  PlayerDetail,
  PlayerList,
  PremiumOrder,
  PremiumOrderDetail,
  SlideDetail,
  SlideList,
  SportDetail,
  SportList,
  UserDetail,
  UserList,
  VideoDetail,
  VideoList,
  EventRankings,
  EquipmentList,
  SponsorDetail,
  SponsorList,
  EquipmentDetail,
  CouponList
} from "./pages";

export const URL_ADMIN = "/";
export default {
  items: [
    {
      path: URL_ADMIN,
      name: "Dashboard",
      type: "submenu",
      icon: AppsIcon,
      sidebarBackgroundColor: "#302d2b",
      children: [
        {
          path: "",
          name: "Overview",
          component: Overview,
          sidebarBackgroundColor: "#302d2b",
        },
        {
          path: "audience-report",
          name: "Audience Report",
          component: AudienceReport,
          sidebarBackgroundColor: "#302d2b",
        },
      ]
    },
    {
      path: URL_ADMIN,
      name: "Home",
      type: "submenu",
      icon: HomeIcon,
      sidebarBackgroundColor: "#02023c",
      children: [
        {
          path: "slider",
          name: "Slider",
          component: SlideList,
          sidebarBackgroundColor: "#20205e",
        },
        {
          path: "slider/:id",
          name: "Slide Detail",
          component: SlideDetail,
          sidebarBackgroundColor: "#20205e",
          invisibleOnSidebar: true,
        },
      ],
    },
    {
      path: URL_ADMIN,
      name: "News",
      type: "submenu",
      icon: AssignmentIcon,
      sidebarBackgroundColor: "#fdc209",
      children: [
        {
          path: "article",
          name: "Articles",
          component: NewsList,
          sidebarBackgroundColor: "#f0b830",
        },
        {
          path: "article/:id",
          name: "Edit Article",
          component: NewsDetail,
          sidebarBackgroundColor: "#f0b830",
          invisibleOnSidebar: true,
        },
        {
          path: "player",
          name: "Players",
          type: "link",
          component: PlayerList,
          sidebarBackgroundColor: "#f0b830",
        },
        {
          path: "player/:id",
          name: "Edit Player",
          type: "link",
          component: PlayerDetail,
          sidebarBackgroundColor: "#f0b830",
          invisibleOnSidebar: true,
        },
      ],
    },
    {
      path: URL_ADMIN,
      name: "TV",
      type: "submenu",
      icon: LiveTvIcon,
      sidebarBackgroundColor: "#e51135",
      children: [
        {
          path: "event",
          name: "Events",
          component: EventList,
          sidebarBackgroundColor: "#e0365b",
        },
        {
          path: "event/:id",
          name: "Event Detail",
          component: EventDetail,
          invisibleOnSidebar: true,
        },
        {
          path: "eventPlaylist/:eventId",
          name: "Event Playlist",
          component: EventPlaylist,
          invisibleOnSidebar: true,
        },
        {
          path: "eventSchedule/:eventId",
          name: "Event Schedule",
          component: EventSchedule,
          invisibleOnSidebar: true,
        },
        {
          path: "rankings/:eventId",
          name: "Event Rankings",
          component: EventRankings,
          invisibleOnSidebar: true,
        },
        {
          path: "eventStreams/:eventId",
          name: "Event Streams",
          component: EventStreams,
          invisibleOnSidebar: true,
        },
        {
          path: "video/:videoId",
          name: "Video Detail",
          component: VideoDetail,
          invisibleOnSidebar: true,
        },
        {
          path: "video",
          name: "Videos",
          component: VideoList,
          sidebarBackgroundColor: "#e0365b",
        },
        {
          path: "import-video",
          name: "Import Videos",
          component: ImportVideos,
          sidebarBackgroundColor: "#e0365b",
        },
        {
          path: "premium-order",
          name: "Premium Order",
          component: PremiumOrder,
          sidebarBackgroundColor: "#e0365b",
        },
        {
          path: "premium-order/:id",
          name: "Premium Order Detail",
          component: PremiumOrderDetail,
          sidebarBackgroundColor: "#e0365b",
          invisibleOnSidebar: true,
        },
      ],
    },
    {
      path: URL_ADMIN,
      name: "Settings",
      type: "submenu",
      icon: SettingsIcon,
      sidebarBackgroundColor: "#302d2b",
      children: [
        {
          path: "configuration",
          name: "Configurations",
          component: ConfigurationList,
        },
        {
          path: "sport",
          name: "Sports",
          component: SportList,
        },
        {
          path: "sport/:id",
          name: "Sport Detail",
          component: SportDetail,
          invisibleOnSidebar: true,
        },
        {
          path: "game",
          name: "Games",
          component: GameList,
        },
        {
          path: "game/:id",
          name: "Game Detail",
          component: GameDetail,
          invisibleOnSidebar: true,
        },
        {
          path: "country",
          name: "Countries",
          component: CountryList,
        },
        {
          path: "country/:id",
          name: "Country Detail",
          component: CountryDetail,
          invisibleOnSidebar: true,
        },
        {
          path: "eventType",
          name: "Event Types",
          component: EventTypeList,
          invisibleOnSidebar: true,
        },
        {
          path: "eventType/:id",
          name: "Event Type Detail",
          component: EventTypeDetail,
          invisibleOnSidebar: true,
        },
        {
          path: "equipment",
          name: "Equipments",
          component: EquipmentList,
        },
        {
          path: "equipment/:id",
          name: "Equipment Detail",
          component: EquipmentDetail,
          invisibleOnSidebar: true,
        },
        {
          path: "sponsor",
          name: "Sponsors",
          component: SponsorList,
        },
        {
          path: "sponsor/:id",
          name: "Sponsor Detail",
          component: SponsorDetail,
          invisibleOnSidebar: true,
        },
        {
          path: "coupon",
          name: "Coupons",
          component: CouponList,
        },
        {
          path: "other",
          name: "Other",
          component: Other,
        },
      ],
    },
    {
      path: URL_ADMIN,
      name: "Admins & Users",
      type: "submenu",
      icon: VerifiedUserOutlinedIcon,
      sidebarBackgroundColor: "#302d2b",
      children: [
        {
          path: "user",
          name: "Users",
          component: UserList,
        },
        {
          path: "user/:id",
          name: "User Detail",
          component: UserDetail,
          invisibleOnSidebar: true,
        },
        {
          path: "administrators",
          name: "Administrators",
          component: Administrators,
        },
      ],
    },
  ],
};
