import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const DataTableHeader = ({
  rows,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  hideCheckbox,
  onRequestSort
}) => {


  return (
    <TableHead>
      <TableRow>
        {!hideCheckbox &&
          <TableCell>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        }
        {rows.map((row) => {
          return (
            <TableCell
              key={row.id}
              numeric={row.numeric ? row.numeric.toString() : undefined}
              padding={row.disablePadding ? "none" : "default"}
              sortDirection={orderBy === row.id ? order : false}
            >
              {row.sortable ?
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={(e) => onRequestSort(e, row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
               :
                <span>{row.label}</span>
              }
            </TableCell>
          );
        }, this)}
      </TableRow>
    </TableHead>
  );
}

DataTableHeader.propTypes = {
  hideCheckbox: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default DataTableHeader;