import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { SERVER_URL } from "constants/serverUrl";
import { getAuthHeader } from "utils";
import { toast } from "react-toastify";


export const useDetailPage = ({
  apiEndpoint,
  autoI18nFromEnglish = false,
  additionalQueryParams = {},
  defaultData = {},
  excludedFields = [],
}) => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const history = useHistory();

  const onSave = () => {
    if (autoI18nFromEnglish) {
      // If there are any missing translations, use English version as default
      const englishTranslation = data.i18n.find(item => item.lang === 'en');
      data.i18n.forEach(item => {
        for (const key in item) {
          if (Object.hasOwnProperty.call(item, key)) {
            const trans = item[key];
            if (!trans && englishTranslation[key]) {
              item[key] = englishTranslation[key]
            }
          }
        }
      });
    }

    setSaving(true);
    if (id === "new") {
      // Exception for order API
      const endpoint = apiEndpoint !== 'order' ? `${SERVER_URL}/${apiEndpoint}` : `${SERVER_URL}/api/${apiEndpoint}`;
      axios.post(endpoint, data, {
        headers: getAuthHeader()
      }).then((res) => {
        setSaving(false);
        toast.success('Created successfully');
        history.push(history.location.pathname.replace(/new$/, res.data.id));
      }).catch(err => {
        toast.error(err.message);
        setSaving(false);
      });
    } else {
      axios.put(`${SERVER_URL}/${apiEndpoint}/${id}`, data, {
        headers: getAuthHeader()
      }).then(() => {
        toast.success('Updated successfully');
        setSaving(false);
      }).catch(err => {
        toast.error(err.message);
        setSaving(false);
      });
    }
  };

  const onUpdateData = (newData) => {
    setData({ ...data, ...newData });
  };

  useEffect(() => {
    if (id && id !== "new") {
      setLoading(true);
      axios.get(`${SERVER_URL}/${apiEndpoint}/${id}`, {
        headers: getAuthHeader(),
        params: additionalQueryParams
      }).then(res => {
        const data = {...res.data};
        excludedFields.forEach(field => {
          delete data[field];
        });
        setData(data);
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        toast.error(err.message);
      })
    }
    if (id === "new") {
      setData(defaultData);
    }
     // eslint-disable-next-line
  }, [id]);

  return {
    id,
    data,
    loading,
    saving,
    onSave,
    onUpdateData,
  };
};

