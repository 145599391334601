
import React from "react";
import { Wrapper } from "components";

import BriefInfoPanel from "./briefInfoPanel";

export const Overview = () => {
  return (
    <Wrapper>
      <BriefInfoPanel />
    </Wrapper>
  );
}