import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

class DeleteDialog extends React.Component {
  render() {
    const { opened, bulk, title } = this.props;
    return (
      <Dialog onClose={this.props.onCancel} open={opened}>
        <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingBottom: "1rem" }}>
            {bulk
              ? "Are you sure want to delete selected items?"
              : "Are you sure want to delete this item?"}
          </DialogContentText>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginRight: "1rem" }}
              onClick={this.props.onConfirm}
            >
              Yes
            </Button>
            <Button variant="contained" onClick={this.props.onCancel}>
              No
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

DeleteDialog.propTypes = {
  opened: PropTypes.bool.isRequired,
  title: PropTypes.oneOf(["Info", "Warning"]),
  bulk: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DeleteDialog;
