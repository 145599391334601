import { Box, Tab, Tabs } from '@mui/material';
import { DynamicFormInputs, getSwitchValue } from 'pages/Common/DynamicFormInputs';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
/**
 * Inherit DynamicFormInputs with languages tabs.
 * DynamicFormInputs has half-width size as default, while TranslationEditor has full-width size as default 
 */
export const TranslationEditor = ({
  inputs = [],
  i18n = [],
  onUpdate,
  langChange = () => {}
}) => {
  const { languages } = useSelector(state => state.lang);
  const [ lang, setLang ] = useState('en');

  const activeTranslation = useMemo(() => {
    return i18n.find(trans => trans.lang === lang);
  }, [i18n, lang]);

  const i18nState = useMemo(() => {
    return languages.map(item => {
      const i18nItem = i18n.find(i => i.lang === item.iso) || {lang: item.iso};

      inputs.forEach(input => {
        i18nItem[input.field] = input.type === 'switch' ? getSwitchValue(i18nItem[input.field], input.dataType) : (i18nItem[input.field] || '');
      });
      return i18nItem;
    })
  }, [inputs, languages, i18n]);

  const inputWithValues = useMemo(() => {
    return inputs.map(input => ({
      ...input,
      // Default size to be full-width
      fullWidth: input.fullWidth === undefined ? true : input.fullWidth,
      value: activeTranslation && activeTranslation[input.field] ?  activeTranslation[input.field] : ""
    }));
  }, [inputs, activeTranslation]);

  const onUpdateTranslation = (values) => {
    const newi18n = i18nState.map(item => {
      if  (item.lang !== lang) {
        return item;
      } else {
        return {
          ...values,
          lang
        }
      }
    });
    onUpdate && onUpdate(newi18n);
  };

  return (
    <Box>
      <Tabs
        value={lang}
        onChange={(_, newLang) => {setLang(newLang); langChange(newLang)}}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        sx={{marginBottom: 3}}
      >
        {languages.map((lang, index) => <Tab key={index} label={lang.label} value={lang.iso} />)}
      </Tabs>
      <DynamicFormInputs inputs={inputWithValues} onChange={onUpdateTranslation} />
    </Box>
  );
};

