import {
  GET_LANGUAGES_START,
  GET_LANGUAGES_FINISH,
  GET_LANGUAGES_ERROR,
  CHANGE_LANGUAGE,
} from "store/actionTypes/language";
import { SERVER_URL } from "constants/serverUrl";
import axios from "axios";

export const getLanguagues = () => {
  return (dispatch) => {
    dispatch({
      type: GET_LANGUAGES_START,
    });
    axios
      .get(
        `${SERVER_URL}/language`
      )
      .then((res) => {
        dispatch({
          type: GET_LANGUAGES_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_LANGUAGES_ERROR,
          payload: err,
        });
      });
  };
};

export const changeLanguage = (lang) => ({
  type: CHANGE_LANGUAGE,
  payload: lang,
});