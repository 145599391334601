import {Edit as EditIcon, DeleteForever as DeleteForeverIcon, Check as CheckIcon} from '@mui/icons-material';
import { DataGridListPage, DataGridActionMenu } from 'pages/Common';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getGameName } from 'utils';
import { formatSeconds } from 'utils/time';
import moment from 'moment';
import { MenuItem } from '@mui/material';
import { pageKeys } from 'store/reducers/dataGrid.reducer';
import { deleteDataGridRow } from 'store/actions';


export const VideoList = () => {
  const gamesById = useSelector(state => state.games.gamesById);
  const history = useHistory();
  const dispatch = useDispatch();

  const onDeleteVideo = useCallback((videoId) => {
    const confirmed = window.confirm("Are you sure you want to delete this video permanently?");
    if (confirmed) {
      dispatch(deleteDataGridRow(`video`, videoId, {}, {}, pageKeys.videoList));
    }
  }, [dispatch]);

  const columns = useMemo(() => {
    return [
      {
        field: 'thumbnail',
        headerName: 'Thumbnail',
        cellRenderer: ({ data }) => <img
          src={`https://kozoom.b-cdn.net/uploads/events/${data.eventId}/videos/${data.thumbnail}`}
          alt="thumb"
          width="100%" />,
        width: 150
      },
      {
        field: 'eventTypeName',
        headerName: 'Event Type',
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
      },
      {
        field: 'isOnline',
        headerName: 'Published',
        width: 100,
        cellStyle: { textAlign: 'center' },
        headerClass: 'text-center',
        cellRenderer: ({ data }) => data.isOnline ? <CheckIcon color="success"/> : null,
      },
      {
        field: 'gameId',
        headerName: 'Game',
        valueFormatter: ({ value }) => getGameName(gamesById, value),
        width: 80,
      },
      {
        field: 'city',
        headerName: 'City',
        width: 120,
      },
      {
        field: 'duration',
        headerName: 'Duration',
        valueFormatter: (({ value }) => formatSeconds(value)),
        width: 100,
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        valueFormatter: ({ value }) => value && moment(value).format('Y-MM-DD'),
        width: 100,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        cellStyle: {textAlign: 'center'},
        headerClass: 'text-center',
        cellRenderer: ({ data }) => (
          <DataGridActionMenu>
            <MenuItem onClick={() => history.push(`/video/${data.id}`)}><EditIcon/>Edit</MenuItem>
            <MenuItem onClick={() => onDeleteVideo(data.id)}><DeleteForeverIcon/>Remove</MenuItem>
          </DataGridActionMenu>
        ),
      },
    ];
  }, [gamesById, history, onDeleteVideo]);

  const filters = [{
    field: 'lang',
    label: 'Language',
    type: 'language',
    value: 'en'
  }];

  return <DataGridListPage
    columns={columns}
    filters={filters}
    apiEndpoint="video"
    searchKey="searchStr"
    heading="Videos"
    pageKey={pageKeys.videoList}
    onAdd={() => history.push(`/video/new`)}
    rowsPerPage={50}
    initialQueryParams={{
      isAdmin: 1,
    }}
  />
}
