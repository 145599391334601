import axios from "axios";
import { SERVER_URL } from "constants/serverUrl";
import { capitalize, isNull, isUndefined, omitBy, uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { socketIo } from "socket";
import { getAuthHeader } from "utils";
import { hmsToSecondsOnly } from 'utils/time';


export const useVideoDetail = () => {
  const { videoId } = useParams();
  const { languages } = useSelector(state => state.lang);

  const defaultVideo = {
    events: [],
    players: [],
    i18n: [],
    showInNewsFeed: false
  };
  
  const [video, setVideo] = useState(defaultVideo);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const history = useHistory();

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.video) {
      const liveScoreVideo = location.state.video;
      setVideo({
        ...video,
        duration: hmsToSecondsOnly(liveScoreVideo.duration),
        i18n: languages.map(item => ({lang: item.iso, description: generateDescription(liveScoreVideo.desc), article: '', isOnline: false})),
        thumbnailSrcUrl: liveScoreVideo.thumbnail,
        videoSrcUrl: liveScoreVideo.stream,
        livescoreImportId: liveScoreVideo.id,
      })
    }
    // eslint-disable-next-line
  }, [location]);

  const updateVideo = (field, value) => {
    if (['events', 'players'].includes(field)) {
      setVideo({
        ...video,
        [field]: uniqBy(value, item => item.id)
      });

    } else if (field === 'event') {
      if (value) {
        setVideo({
          ...video,
          eventId: value.id,
          events: uniqBy([value, ...video.events], item => item.id)
        });
      } else {
        setVideo({
          ...video,
          eventId: null,
        });
      }
    } else {
      setVideo({
        ...video,
        [field]: value
      });
    }
  }

  const onSaveVideo = () => {
    // Default translation to be English version if the content is empty
    const englishTranslation = video.i18n.find(item => item.lang === 'en');
    video.i18n = video.i18n.map(item => ({
      ...item,
      description: item.description ? item.description : englishTranslation.description,
      article: item.article ? item.article : englishTranslation.article,
    }));
  
    const data = omitBy({
      ...video,
      eventIds: video.events.map(event => event.id),
      playerIds: video.players.map(player => player.id),
      events: undefined,
      players: undefined,
      livescoreImportId: Number(video.livescoreImportId) || null,
    }, (v) => isUndefined(v) || isNull(v));
    

    if (!data.eventId) {
      toast.error("You must select Main Event!");
    } else if (!data.thumbnail) {
      toast.error("You must select a thumbnail!");
    } else if (!data.path) {
      toast.error("You must select a video!");
    } else if (videoId) {
      setSaving(true);
      if (videoId === 'new') {
        axios.post(`${SERVER_URL}/video`, data, {
          headers: getAuthHeader()
        }).then((res) => {
          history.goBack();
          setSaving(false);
          toast.success('Video created successfuly');
        }).catch(err => {
          setSaving(false);
          toast.error(err.message);
        });
      } else {
        axios.put(`${SERVER_URL}/video/${videoId}`, data, {
          headers: getAuthHeader()
        }).then((res) => {
          setSaving(false);
          toast.success('Video updated successfuly');
        }).catch(err => {
          setSaving(false);
          toast.error(err.message);
        });
      }
    }
  };

  useEffect(() => {
    if (videoId && videoId !== 'new') {
      setLoading(true);
      axios.get(`${SERVER_URL}/video/${videoId}`, {
        headers: getAuthHeader()
      }).then((res) => {
        setVideo({
          ...res.data,
          videoUrl: `${res.data.baseVideoUrl}?session=${socketIo.id}&mag_id=NEW`
        });
        setLoading(false);
      }).catch(err => {
        toast.error(err.message);
      });
    }
  // eslint-disable-next-line
  }, []);

  return {
    videoId,
    loading,
    saving,
    video,
    updateVideo,
    onSaveVideo,
  };
};

const generateDescription = (description) => {
  if (!description) {
    return '';
  }
  const parts = description.split(/\[(\d+)\]/);
  if (parts.length === 3) {
    const players = parts[2].split('/');
    if (players.length !== 2) {
      return description;
    }
    const player1Arr = players[0].split(',');
    const player2Arr = players[1].split(',');
    return `${capitalize(player1Arr[1]?.trim())} ${capitalize(player1Arr[0]?.trim())} vs ${capitalize(player2Arr[1]?.trim())} ${capitalize(player2Arr[0]?.trim())} ► ${parts[0].trim()} ► Race to ${parts[1]}`;
  }
  return description;
}

