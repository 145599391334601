import { MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";

export const SportSelect = ({ sportId, onChange = () => {}, placeholder="Sport", disabled=false }) => {
  const [value, setValue] = useState(sportId || 1);
  const sports = useSelector(state => state.sports.data);
  const onSportChange = (e) => {
    setValue(e.target.value);
    onChange(e.target.value)
  }
  return (
    <FormControl size="small" fullWidth>
      <InputLabel id="sportSelect">{placeholder}</InputLabel>
      <Select
        disabled={disabled}
        labelId="sportSelect"
        value={value}
        label={placeholder}
        onChange={onSportChange}
      >
        {sports.map(sport => <MenuItem key={sport.id} value={sport.id}>{sport.name}</MenuItem>)}
      </Select>
    </FormControl>
  )
}