import React, { useEffect } from "react";
import { MenuItem, FormControl, InputLabel, Select, ListSubheader } from "@mui/material";

import { useSelector } from "react-redux";

let domIndex = 1;

export const GameSelect = ({gameId, onChange = () => {}, placeholder="Game", disabled=false}) => {
  useEffect(() => {
    domIndex ++;
  }, []);

  const games = useSelector(state => state.games);
  const sports = useSelector(state => state.sports);

  const gamesGroupedBySports = sports.data.map(sport => ({
    ...sport,
    games: games.data.filter(item => Number(item.sportId) === Number(sport.id))
  }));

  return (
    <FormControl fullWidth size="small">
      <InputLabel htmlFor={`game-select-${domIndex}`}>{placeholder}</InputLabel>
      <Select
        id={`game-select-${domIndex}`}
        label={placeholder}
        disabled={disabled}
        value={gameId}
        onChange={e => onChange(e.target.value)}>
        <MenuItem value={""}>Select Game</MenuItem>
        {gamesGroupedBySports.map(sport => 
          [
            <ListSubheader key={sport.id} color="primary">{sport.name}</ListSubheader>,
            sport.games.map(item => 
              <MenuItem key={item.id} value={item.id}>{item ? item.name : ''}</MenuItem>
            )
          ]
        )}
      </Select>
    </FormControl>
  );
}

