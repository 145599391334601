import {Edit as EditIcon, Check as CheckIcon, Close as CloseIcon} from '@mui/icons-material';
import { DataGridListPage, DataGridActionMenu } from 'pages/Common';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import { pageKeys } from 'store/reducers/dataGrid.reducer';


export const ImportVideos = () => {
  const history = useHistory();

  const columns = useMemo(() => {
    return [
      {
        field: 'id',
        headerName: 'Id',
        width: 80
      },
      {
        field: 'thumbnail',
        headerName: 'Thumbnail',
        cellRenderer: ({ data }) => <img
          src={data.thumbnail}
          alt="thumb"
          width="100%" />,
        width: 150
      },
      {
        field: 'start',
        headerName: 'Recorded At',
        width: 180,
      },
      {
        field: 'duration',
        headerName: 'Duration',
        width: 100,
      },
      {
        field: 'desc',
        headerName: 'Description',
        flex: 1,
      },
      {
        field: 'imported',
        headerName: 'Imported',
        cellStyle: {textAlign: 'center'},
        headerClass: 'text-center',
        cellRenderer: ({ data }) => data.videoId ? <CheckIcon color="success"/> : <CloseIcon color="danger"/>,
        width: 100,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        cellStyle: {textAlign: 'center'},
        headerClass: 'text-center',
        cellRenderer: ({ data }) => (
          <DataGridActionMenu>
            {data.videoId &&
            <MenuItem onClick={() => history.push(`/video/${data.videoId}`)}><EditIcon/>Edit Video</MenuItem>
            }
            {!data.videoId &&
              <MenuItem onClick={() => history.push(`/video/new`, {video: data})}><EditIcon/>Import</MenuItem>
            }
          </DataGridActionMenu>
        ),
      },
    ];
  }, [history]);

  const filters = [];

  return <DataGridListPage
    columns={columns}
    filters={filters}
    apiEndpoint="liveScoreVideo"
    searchPlaceholder="Room Token"
    searchKey="roomToken"
    hasPagination={false}
    heading="Import Videos From Live Score"
    pageKey={pageKeys.importVideos}
    requiredQueryParams={['roomToken']}
  />
}

