import {
  GET_DATA_GRID_LIST,
  GET_DATA_GRID_LIST_SUCCESS,
  GET_DATA_GRID_LIST_FAIL,

  DELETE_DATA_GRID_ROW,
  DELETE_DATA_GRID_ROW_SUCCESS,
  DELETE_DATA_GRID_ROW_FAIL,

  SAVE_ROW_RANKINGS,
  SAVE_ROW_RANKINGS_SUCCESS,
  SAVE_ROW_RANKINGS_FAIL,

  ADD_DATA_GRID_ROW,
  ADD_DATA_GRID_ROW_SUCCESS,
  ADD_DATA_GRID_ROW_FAIL,

  EDIT_DATA_GRID_ROW,
  EDIT_DATA_GRID_ROW_SUCCESS,
  EDIT_DATA_GRID_ROW_FAIL,
} from "store/actions/dataGrid.actions";

export const pageKeys = {
  slideList: 'slideList',

  eventList: 'eventList',
  eventPlaylist: 'eventPlaylist',
  videoList: 'videoList',
  importVideos: 'importVideos',
  orderList: 'orderList',
  configurationList: 'configurationList',
  eventSchedule: 'eventSchedule',
  eventStreams: 'eventStreams',
  eventRankings: 'eventRankings',

  newsList: 'newsList',
  playerList: 'playerList',

  sportList: 'sportList',
  gameList: 'gameList',
  countryList: 'countryList',
  eventTypeList: 'eventTypeList',
  userList: 'userList',
  equipmentList: 'equipmentList',
  sponsorList: 'sponsorList',
  couponList: 'couponList'
}

const initPageState = {
  loading: false,
  dataById: {},
  ids: [],
  error: null,
};

// initial state is an object with all keys from pageKeys and values are initPageState
const initialState = Object.keys(pageKeys).reduce((a, k) => {
  return {
    ...a,
    [k]: initPageState
  }
}, {});

export default function (state = initialState, action) {
  const pageKey = action.payload && action.payload.pageKey ? action.payload.pageKey : null;
  switch (action.type) {
    case GET_DATA_GRID_LIST:
    case SAVE_ROW_RANKINGS:
    case ADD_DATA_GRID_ROW:
    case EDIT_DATA_GRID_ROW:
    case DELETE_DATA_GRID_ROW:
      return {
        ...state,
        [pageKey]: {
          ...state[pageKey],
          loading: true,
          error: null
        }
      }
    case GET_DATA_GRID_LIST_SUCCESS: {
      if (Array.isArray(action.payload.data)) {
        const dataById = {};
        const ids = [];
        action.payload.data.forEach(item => {
          dataById[item.id] = item;
          ids.push(item.id);
        });
        return {
          ...state,
          [pageKey]: {
            ...state[pageKey],
            dataById,
            ids,
            loading: false,
            error: null
          }
        }
      } else {
        return state;
      }
    }

    case DELETE_DATA_GRID_ROW_SUCCESS: {
      const { rowId } = action.payload;
      const dataById = { ...state[pageKey].dataById };
      const ids = [...state[pageKey].ids];
      delete dataById[rowId];
      ids.splice(ids.indexOf(rowId), 1);
      return {
        ...state,
        [pageKey]: {
          ...state[pageKey],
          dataById,
          ids,
          loading: false
        }
      }
    }
    case ADD_DATA_GRID_ROW_SUCCESS: {
      const { data } = action.payload;
      const dataById = { ...state[pageKey].dataById, [data.id]: data };
      const ids = [data.id, ...state[pageKey].ids];

      return {
        ...state,
        [pageKey]: {
          ...state[pageKey],
          dataById,
          ids,
          loading: false
        }
      }
    }
    case EDIT_DATA_GRID_ROW_SUCCESS: {
      const { data } = action.payload;
      const dataById = { ...state[pageKey].dataById, [data.id]: data };
      return {
        ...state,
        [pageKey]: {
          ...state[pageKey],
          dataById,
          loading: false
        }
      }
    }
    case SAVE_ROW_RANKINGS_SUCCESS:
      return {
        ...state,
        [pageKey]: {
          ...state[pageKey],
          loading: false
        }
      }
    case GET_DATA_GRID_LIST_FAIL:
    case SAVE_ROW_RANKINGS_FAIL:
    case DELETE_DATA_GRID_ROW_FAIL:
    case ADD_DATA_GRID_ROW_FAIL:
    case EDIT_DATA_GRID_ROW_FAIL:
      return {
        ...state,
        [pageKey]: {
          ...state[pageKey],
          error: action.payload.error,
          loading: false
        }
      }
    default:
      return state;
  }
}
