import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Checkbox,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Alert } from '@mui/material';

import {
  action_get_administrators,
  action_delete_administrators,
} from "store/actions";
import {
  Wrapper,
  DataTableHeader,
  DataTableToolbar,
  DataTablePagination,
  DeleteDialog,
} from "components";
import { getSorting, findItemById } from "utils";
import ManageDialog, { RoleList } from "./manageDialog";

/**
 *
 * @param {array} data
 */
const arrangeData = (data) => {
  let res = [];
  for (var item of data) {
    let role = item.role;
    if (!role) role = "{}";
    let roleJson = {};
    try {
      roleJson = JSON.parse(role);
    } catch (_) {
      roleJson = {};
    }
    res.push({
      id: item.id,
      email: item.email,
      role: item.role,
      ...roleJson,
    });
  }
  return res;
};

const HEADER = [
  {
    id: "email",
    numeric: false,
    
    sortable: true,
    label: "Email",
  },
];

RoleList.forEach(
  (item) =>
    item &&
    !item.invisibleOnList &&
    HEADER.push({
      ...item,
      numeric: false,
      
      sortable: true,
    })
);

HEADER.push({
  id: "action",
  numeric: false,
  
  sortable: false,
  label: "Actions",
});

class AdministratorListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "",
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 10,
      bDeleteDialogOpened: false,
      deleteItemId: -1,
      bBulkDelete: false,
      bEditDialogOpened: false,
      editDataId: -1,
      bNewItem: false,
    };

    this.props.action_get_administrators();
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    const { adminList } = this.props;
    if (checked) {
      this.setState({ selected: adminList.data.map((n) => n.id) });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  _onDeleteItems = () => {
    const { deleteItemId, bBulkDelete, selected } = this.state;

    let deleteIDs = [];
    if (bBulkDelete) deleteIDs = selected;
    else deleteIDs = [deleteItemId];
    this.props.action_delete_administrators(deleteIDs);
    this.setState({ bDeleteDialogOpened: false, selected: [] });
  };

  parseRole = (role) => {
    if (role === 3) return "R / W";
    else if (role === 2) return "R";
    return "";
  };

  _renderTableData = (data) => {
    const { order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    return (
      <TableBody>
        {data
          .sort(getSorting(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((n) => {
            const isSelected = this.isSelected(n.id);
            return (
              <TableRow
                hover
                role="checkbox"
                aria-checked={isSelected}
                tabIndex={-1}
                key={n.id}
                selected={isSelected}
              >
                <TableCell padding="none">
                  <Checkbox
                    checked={isSelected}
                    onClick={(event) => this.handleClick(event, n.id)}
                  />
                </TableCell>
                <TableCell padding="none">{n.email}</TableCell>
                {RoleList.map((item, index) => {
                  if (!item || item.invisibleOnList) return null;
                  return (
                    <TableCell key={index} padding="none">
                      {this.parseRole(n[item.id])}
                    </TableCell>
                  );
                })}
                <TableCell padding="none">
                  <IconButton
                    onClick={() =>
                      this.setState({
                        bEditDialogOpened: true,
                        editDataId: n.id,
                        bNewItem: false,
                      })
                    }
                    size="large">
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      this.setState({
                        bDeleteDialogOpened: true,
                        deleteItemId: n.id,
                        bBulkDelete: false,
                      })
                    }
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        {emptyRows > 0 && (
          <TableRow style={{ height: 25 * emptyRows }}>
            <TableCell colSpan={15} />
          </TableRow>
        )}
      </TableBody>
    );
  };

  _renderError = () => {
    const { adminList } = this.props;
    if (!adminList.loading && adminList.err) {
      return (
        <Alert variant="filled" severity="error">
          {typeof adminList.err === "string" && adminList.err}
        </Alert>
      );
    }
  };

  render() {
    const { adminList, lang } = this.props;
    const data = arrangeData(adminList.data, lang.currentLanguage);

    const {
      order,
      orderBy,
      selected,
      searchKey,
      rowsPerPage,
      page,
      bDeleteDialogOpened,
      bBulkDelete,
      bEditDialogOpened,
      editDataId,
      bNewItem,
    } = this.state;

    return (
      <Wrapper>
        <Paper style={{ padding: 10 }}>
          <DataTableToolbar
            label="Administrator List"
            lang={lang}
            numSelected={selected.length}
            searchKey={searchKey}
            onSearchKeyChanged={(value) => this.setState({ searchKey: value })}
            onDeleteIconPressed={() =>
              this.setState({
                bDeleteDialogOpened: true,
                bBulkDelete: true,
              })
            }
            onAddIconPressed={() =>
              this.setState({
                bEditDialogOpened: true,
                editDataId: -1,
                bNewItem: true,
              })
            }
          />
          {this._renderError()}
          {adminList.loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <Table aria-labelledby="tableTitle">
                <DataTableHeader
                  rows={HEADER}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={data.length}
                />
                {this._renderTableData(data)}
              </Table>
              <TablePagination
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25, 50]}
                page={page}
                backIconButtonProps={{
                  "aria-label": "Previous Page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page",
                }}
                ActionsComponent={DataTablePagination}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </div>
          )}
          {bDeleteDialogOpened ? (
            <DeleteDialog
              opened={Boolean(bDeleteDialogOpened)}
              title="Warning"
              bulk={Boolean(bBulkDelete)}
              onConfirm={this._onDeleteItems}
              onCancel={() => this.setState({ bDeleteDialogOpened: false })}
            />
          ) : null}
          {bEditDialogOpened ? (
            <ManageDialog
              isNew={bNewItem}
              onClose={() => this.setState({ bEditDialogOpened: false })}
              data={findItemById(adminList.data, editDataId)}
            />
          ) : null}
        </Paper>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adminList: state.a_administrators,
    lang: state.lang,
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      action_get_administrators,
      action_delete_administrators,
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  matchDispatchToProps
)(AdministratorListPage);
