import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import Filter from "components/Filter";
import DataTableToolbarStyles from "../../styles/Components/Datatable/toolbar";

class DataTableToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorFilter: null,
    };
  }
  render() {
    const { anchorFilter } = this.state;
    const {
      label,
      numSelected,
      classes,
      searchKey,
      hideAddIcon,
      hideDelIcon,
      onSearchKeyChanged,
      onDeleteIconPressed,
      onAddIconPressed,
      onSearch,
      FilterComponent,
      additionalActions,
    } = this.props;

    return (
      <Toolbar
        className={classNames(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <div className={classes.title}>
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subheading">
              {numSelected} selected
            </Typography>
          ) : (
            <Typography variant="h6" id="tableTitle">
              {label}
            </Typography>
          )}
        </div>
        <div className={classes.spacer}>
          {!numSelected ? (
            <div className={classes.searchWrapper}>
              <form className={classes.searchForm} onSubmit={onSearch}>
                <input
                  className={classNames(classes.kulimFont, classes.searchInput)}
                  type="text"
                  placeholder="search"
                  value={searchKey}
                  onChange={(e) => onSearchKeyChanged(e.target.value)}
                />
                <IconButton
                  aria-label="Search"
                  className={classes.searchIcon}
                  onClick={onSearch}
                  size="large">
                  <SearchIcon />
                </IconButton>
              </form>
            </div>
          ) : null}
        </div>
        <div className={classes.actions}>
          {hideAddIcon || numSelected > 0 ? null : (
            <Tooltip title="Add New Item">
              <IconButton aria-label="Add New Item" onClick={onAddIconPressed} size="large">
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className={classes.actions}>
          {numSelected > 0 && additionalActions ? additionalActions : null}
          {numSelected > 0 ? (
            hideDelIcon ? null : (
              <Tooltip title="Delete">
                <IconButton aria-label="Delete" onClick={onDeleteIconPressed} size="large">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )
          ) : (
            FilterComponent ? (
              <Tooltip title="Filter list">
                <IconButton
                  aria-label="Filter list"
                  onClick={(event) =>
                    this.setState({ anchorFilter: event.currentTarget })
                  }
                  size="large">
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            ) : null
          )}
        </div>
        {FilterComponent ? (
          <Filter
            anchorEl={anchorFilter}
            open={Boolean(anchorFilter)}
            onClose={() => this.setState({ anchorFilter: null })}
          ><FilterComponent /></Filter>
        ) : null}
      </Toolbar>
    );
  }
}

DataTableToolbar.propTypes = {
  label: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  searchKey: PropTypes.string,
  onSearchKeyChanged: PropTypes.func,
  onSearch: PropTypes.func,
  FilterComponent: PropTypes.object,

  hideAddIcon: PropTypes.bool,
  hideDelIcon: PropTypes.bool,
};

export default withStyles(DataTableToolbarStyles)(DataTableToolbar);
