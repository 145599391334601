import { DatePicker } from '@mui/x-date-pickers';
import {
  Box, Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  TextField
} from '@mui/material';
import Axios from 'axios';
import { TranslationEditor } from 'components/TranslationEditor';
import { SERVER_URL } from 'constants/serverUrl';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { addDataGridRow, editDataGridRow } from 'store/actions';
import { pageKeys } from 'store/reducers/dataGrid.reducer';
import moment from 'moment';

const defaultDate = new Date();
const defaultTime = '09:00';

export const EventDayAddEdit = ({ eventDayId, eventId, open, onClose }) => {
  const [data, setData] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (eventDayId) {
      Axios.get(`${SERVER_URL}/eventDay/${eventDayId}`).then((res) => {
        setData(res.data);
      }).catch((e) => {
        toast.error(e.message);
      });
    } else {
      setData(null);
    }
  }, [eventDayId]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!data) {
      return;
    }
    // Default translation to be English version if the content is empty
    const englishTranslation = data.i18n.find(item => item.lang === 'en');
    data.i18n = data.i18n.map(item => ({
      ...item,
      planning: item.planning ? item.planning : englishTranslation.planning,
    }));

    if (!data.date) {
      data.date = defaultDate;
    }
    if (!data.time) {
      data.time = defaultTime;
    }

    if (eventDayId) {
      dispatch(editDataGridRow('eventDay', eventDayId, data, {}, pageKeys.eventSchedule));
    } else {
      dispatch(addDataGridRow('eventDay', { ...data, eventId: eventId }, {}, pageKeys.eventSchedule));
    }
    onClose();
  }

  return (
    <Dialog onClose={onClose} maxWidth="md" fullWidth open={open}>
      <form onSubmit={onSubmit}>
        <DialogTitle>{eventDayId ? 'Edit' : 'Add'} Event Day</DialogTitle>
        <DialogContent>
          <Box my={2} display="flex">
            <Box mr={2}>
              <DatePicker
                fullWidth
                ampm={false}
                label="Date"
                value={moment(data ? data.date : defaultDate)}
                onChange={(date) => setData({ ...data, date })}
                slotProps={{ textField: { size: 'small' } }}
              />
            </Box>
            <Box>
              <TextField
                label="Time"
                value={data ? data.time : defaultTime}
                onChange={(e) => setData({ ...data, time: e.target.value })}
                size="small" />
            </Box>
          </Box>
          <TranslationEditor
            i18n={data ? data.i18n : []}
            inputs={[
              { field: 'planning', type: 'richtext', label: 'Planning' },
            ]}
            onUpdate={i18n => setData({ ...data, i18n })} />

        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

