import React from "react";

import { Wrapper } from "components";

import { Box, Button } from "@mui/material";
import Axios from "axios";
import { SERVER_URL } from "constants/serverUrl";
import { getAuthHeader } from "utils";
import { toast } from "react-toastify";

export const Other = () => {
  const clearAllStreams = () => {
    const confirmed = prompt("Are you sure you want to delte all streams? All live streams will be deleted permanently. Enter 'Delete All' to confirm");
    if (confirmed === "Delete All") {
      Axios.delete(`${SERVER_URL}/stream`, {headers: getAuthHeader()}).then(() => {
        toast.info("Deleted all streams");
      }).catch((e) => {
        toast.error(e.message || "Something went wrong");
      });
    } else {
      toast.warning("No streams were deleted!");
    }
  };
  return (
    <Wrapper>
      <Box mt={2}>
        <Button variant="contained" color="secondary" onClick={clearAllStreams} >Clear All Streams</Button>
      </Box>
    </Wrapper>
  );
}