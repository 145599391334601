import React from "react";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Switch
} from "@mui/material";
import { EventSelect, MediaUpload, PlayerSelect, TranslationEditor } from "components";
import ListSection from "components/ListSection";
import moment from "moment";
import { DetailHeading } from "pages/Common";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { getGameName } from "utils";
import { formatEventDate } from "utils/time";
import { useVideoDetail } from "./hooks";

export const VideoDetail = () => {
  const {
    loading,
    saving,
    videoId,
    video,
    updateVideo,
    onSaveVideo,
  } = useVideoDetail();
  const gamesById = useSelector(state => state.games.gamesById);
  const getEventName = useCallback((option) => {
    return `${option.id}, ${getGameName(gamesById, option.gameId)}, ${option.eventTypeName}, ${option.city}`
  }, [gamesById]);

  if (loading && !video.id) {
    return (
      <Paper>
        <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
          <CircularProgress />
        </Box>
      </Paper>
    );
  }

  if (!video.id && videoId !== 'new') {
    return null;
  }

  return (
    <>
      <Paper>
        <Box p={2}>
          <DetailHeading
            listLink="/video"
            listName="Videos"
            title={videoId === 'new' ? "New Video" : !video.livescoreImportId ? `Video ${videoId}` : `Import Video with Ref ${video.livescoreImportId}`}
            saving={saving}
            onSave={onSaveVideo}
          />
          <Grid container spacing={2} mb={2}>
            <Grid item lg={4} md={6} sm={12}>
              <Paper variant="outlined">
                <Box p={2}>
                  <EventSelect
                    eventId={video.eventId || null}
                    disabled={videoId !== 'new'}
                    placeholder="Main Event"
                    params={{
                      startBefore: moment().format('Y-MM-DD'),
                    }}
                    onChange={(event) => updateVideo("event", event)}
                  />
                  <Box mt={2}>
                   <ListSection
                      getItemRouterLink={(event) => `/eventPlaylist/${event.id}`}
                      sectionTitle="Playlists"
                      modalTitle="Select a playlist"
                      noItemsMessage="There are no events for this video"
                      items={video.events}
                      selectComponent={EventSelect}
                      onUpdate={(events) => updateVideo("events", events)}
                      getPrimaryLabel={getEventName}
                      getSecondaryLabel={(event) => `(${formatEventDate(event.startTime)} - ${formatEventDate(event.endTime)})`}
                    />
                  </Box>
                  <Box>
                    <ListSection
                      getItemRouterLink={(player) => `/player/${player.id}`}
                      sectionTitle="Players"
                      modalTitle="Select a player"
                      noItemsMessage="There are no players linked to this video"
                      items={video.players}
                      selectComponent={PlayerSelect}
                      onUpdate={(players) => updateVideo("players", players)}
                      getPrimaryLabel={(player) => `${player.firstName} ${player.lastName}`}
                    />
                  </Box>
                  <Box mt={2}>
                    <FormControlLabel control={
                      <Switch
                        checked={Boolean(video.showInNewsFeed)}
                        onChange={(e) => updateVideo('showInNewsFeed', e.target.checked)}
                      />
                    } label="Show in News Feed" />
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item lg={4} md={6} sm={12}>
              <MediaUpload
                component="img"
                label="Thumbnail"
                path={`uploads/events/${video.eventId}/videos`}
                fileName={video.thumbnail}
                sourceUrl={video.thumbnailSrcUrl}
                disabled={!video.eventId}
                onUploaded={(fileName) => updateVideo("thumbnail", fileName)}
              />
            </Grid>
            <Grid item lg={4} md={6} sm={12}>
              <MediaUpload
                component="video"
                label="Video"
                path={`/uploads/events/${video.eventId}/videos`}
                sourceUrl={video.videoSrcUrl}
                fileUrl={video.videoUrl}
                saveLocal={true}
                disabled={!video.eventId}
                onUploaded={(fileName) => updateVideo("path", fileName)}
                onVideoDurationChange={(duration) => updateVideo("duration", Math.floor(duration))}
              />
            </Grid>
          </Grid>
          <Paper variant="outlined">
            <Box p={2}>
              {video.i18n && (
                <TranslationEditor
                  i18n={video.i18n}
                  inputs={[
                    { field: 'isOnline', type: 'switch', label: 'Published' },
                    { field: 'description', type: 'multiline', label: 'Description' },
                    { field: 'article', type: 'richtext', label: 'Article for Masterclass' },
                  ]}
                  onUpdate={i18n => updateVideo("i18n", i18n)} />
              )}
            </Box>
          </Paper>
        </Box>
      </Paper>
    </>
  );
};

