import { SERVER_URL } from "constants/serverUrl";
import { getAuthHeader } from "utils";
import axios from 'axios';
import { pageKeys } from "store/reducers/dataGrid.reducer";
import { unionBy } from "lodash";
import { getBookmarkedEvents } from "utils/event";

export const GET_DATA_GRID_LIST = 'GET_DATA_GRID_LIST';
export const GET_DATA_GRID_LIST_SUCCESS = 'GET_DATA_GRID_LIST_SUCCESS';
export const GET_DATA_GRID_LIST_FAIL = 'GET_DATA_GRID_LIST_FAIL';

export const SAVE_ROW_RANKINGS = 'SAVE_ROW_RANKINGS';
export const SAVE_ROW_RANKINGS_SUCCESS = 'SAVE_ROW_RANKINGS_SUCCESS';
export const SAVE_ROW_RANKINGS_FAIL = 'SAVE_ROW_RANKINGS_FAIL';

export const ADD_DATA_GRID_ROW = 'ADD_DATA_GRID_ROW';
export const ADD_DATA_GRID_ROW_SUCCESS = 'ADD_DATA_GRID_ROW_SUCCESS';
export const ADD_DATA_GRID_ROW_FAIL = 'ADD_DATA_GRID_ROW_FAIL';

export const EDIT_DATA_GRID_ROW = 'EDIT_DATA_GRID_ROW';
export const EDIT_DATA_GRID_ROW_SUCCESS = 'EDIT_DATA_GRID_ROW_SUCCESS';
export const EDIT_DATA_GRID_ROW_FAIL = 'EDIT_DATA_GRID_ROW_FAIL';

export const DELETE_DATA_GRID_ROW = 'DELETE_DATA_GRID_ROW';
export const DELETE_DATA_GRID_ROW_SUCCESS = 'DELETE_DATA_GRID_ROW_SUCCESS';
export const DELETE_DATA_GRID_ROW_FAIL = 'DELETE_DATA_GRID_ROW_FAIL';

/**
 * 
 * @param {*} apiPath 
 * @param {*} params 
 * @param {*} pageKey key of Page. This is the key to separate data between pages
 */
export const getDataGridList = (apiPath, params, pageKey) => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_GRID_LIST,
      payload: {
        pageKey
      }
    });
    axios
      .get(`${SERVER_URL}/${apiPath}`, {
        headers: getAuthHeader(),
        params
      })
      .then((res) => {
        let data = res.data;
        // To show the bookmarked events on top
        if (pageKey === pageKeys.eventList) {
          data = unionBy([...getBookmarkedEvents(), ...data], 'id');
        }

        dispatch({
          type: GET_DATA_GRID_LIST_SUCCESS,
          payload: { data, pageKey },
        });
      })
      .catch((err) => {
        handleError(dispatch, GET_DATA_GRID_LIST_FAIL, err, pageKey);
      });
  };
}

export const addDataGridRow = (apiPath, data = {}, params = {}, pageKey) => {
  return (dispatch) => {
    dispatch({
      type: ADD_DATA_GRID_ROW,
      payload: {
        pageKey
      }
    });
    axios
      .post(`${SERVER_URL}/${apiPath}`,
        data,
        {
          headers: getAuthHeader(),
          params,
        })
      .then((res) => {
        dispatch({
          type: ADD_DATA_GRID_ROW_SUCCESS,
          payload: {
            data: res.data,
            pageKey,
          },
        });
      })
      .catch((err) => {
        handleError(dispatch, ADD_DATA_GRID_ROW_FAIL, err, pageKey);
      });
  };
}

export const editDataGridRow = (apiPath, rowId, data = {}, params = {}, pageKey) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_DATA_GRID_ROW,
      payload: {
        pageKey
      }
    });
    axios
      .put(`${SERVER_URL}/${apiPath}/${rowId}`,
        data,
        {
          headers: getAuthHeader(),
          params,
        })
      .then((res) => {
        dispatch({
          type: EDIT_DATA_GRID_ROW_SUCCESS,
          payload: {
            rowId,
            data: res.data,
            pageKey,
          },
        });
      })
      .catch((err) => {
        handleError(dispatch, EDIT_DATA_GRID_ROW_FAIL, err, pageKey);
      });
  };
}

export const deleteDataGridRow = (apiPath, rowId, data = {}, params = {}, pageKey) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_DATA_GRID_ROW,
      payload: {
        pageKey
      }
    });
    axios
      .delete(rowId ? `${SERVER_URL}/${apiPath}/${rowId}` : `${SERVER_URL}/${apiPath}`,
        {
          headers: getAuthHeader(),
          params,
          data
        })
      .then((res) => {
        dispatch({
          type: DELETE_DATA_GRID_ROW_SUCCESS,
          payload: {
            rowId,
            pageKey,
          },
        });
      })
      .catch((err) => {
        handleError(dispatch, DELETE_DATA_GRID_ROW_FAIL, err, pageKey);
      });
  };
}

export const saveRowRankings = (apiPath, data = {}, params = {}, pageKey) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_ROW_RANKINGS,
      payload: {
        pageKey
      }
    });
    axios
      .put(`${SERVER_URL}/${apiPath}`,
        data,
        {
          headers: getAuthHeader(),
          params,
        })
      .then((res) => {
        dispatch({
          type: SAVE_ROW_RANKINGS_SUCCESS,
          payload: {
            pageKey
          }
        });
      })
      .catch((err) => {
        handleError(dispatch, SAVE_ROW_RANKINGS_FAIL, err, pageKey);
      });
  };
}

const handleError = (dispatch, action, err, pageKey) => {
  if (err.message) {
    dispatch({
      type: action,
      payload: {
        error: err.message,
        pageKey
      },
    });
  } else if (err.response && err.response.data) {
    dispatch({
      type: action,
      payload: {
        error: err.response.data.msg || err.response.data.err,
        pageKey
      },
    });
  } 
}